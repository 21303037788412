import { VueConstructor } from 'vue/types/umd';
import VueLazyload from 'vue-lazyload';

export function setDirectives(vue: VueConstructor<Vue>): void {
    vue.use(VueLazyload, {
        preLoad: 1.3,
        error: 'https://editor-assets.chillnn.com/img/CHILLNN_LOADING.png',
        loading: 'https://editor-assets.chillnn.com/icon/loading-transparent.gif',
        attempt: 1,
        scale: 0.3,
    });

    vue.directive('body-lock', {
        update: (_, binding) => {
            if (binding.value) {
                const bodyElement: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName('body');
                const scrollY: number = window.scrollY;
                if (bodyElement) {
                    bodyElement[0].classList.add('fixed_for_studio');
                    bodyElement[0].style.top = `-${scrollY}px`;
                }
            } else {
                const bodyElement: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName('body');
                if (bodyElement) {
                    const yScrollPx = -Number(bodyElement[0].style.top.replace('px', ''));
                    bodyElement[0].classList.remove('fixed_for_studio');
                    window.scrollTo(0, yScrollPx);
                }
            }
        },
        unbind: () => {
            const bodyElement: HTMLCollectionOf<HTMLBodyElement> = document.getElementsByTagName('body');
            if (bodyElement) {
                bodyElement[0].classList.remove('fixed_for_studio');
            }
        },
    });
}
