
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import DropShadowInput from '../../Molecule/DropShadowInput.vue'
@Component({
    components: {
        QuestionTemplate,
        DropShadowInput,
    },
})
export default class QuestionDropShadow extends Vue {
    @Prop({ required: true }) responsiveModel!: ResponsiveStyleModel
    @Prop({ required: true }) mode!: 'pc' | 'mobile'

    public get statement(): string {
        if (this.mode === 'pc') {
            return 'PCのシャドウ設定'
        } else if (this.mode === 'mobile') {
            return 'モバイルのシャドウ設定'
        }
        return ''
    }

    public get dropShadowColor(): string {
        return this.responsiveModel.dropShadow!.color
    }
    public set dropShadowColor(input: string) {
        this.responsiveModel.dropShadow!.color = input
    }
    public get dropShadowOpacity(): number {
        return this.responsiveModel.dropShadow!.opacity
    }
    public set dropShadowOpacity(input: number) {
        this.responsiveModel.dropShadow!.opacity = input
    }
    public get dropShadowOffsetX(): number {
        return this.responsiveModel.dropShadow!.dropShadowOffsetX
    }
    public set dropShadowOffsetX(input: number) {
        this.responsiveModel.dropShadow!.dropShadowOffsetX = input
    }
    public get dropShadowOffsetY(): number {
        return this.responsiveModel.dropShadow!.dropShadowOffsetY
    }
    public set dropShadowOffsetY(input: number) {
        this.responsiveModel.dropShadow!.dropShadowOffsetY = input
    }
    public get dropShadowBlurRadius(): number {
        return this.responsiveModel.dropShadow!.dropShadowBlurRadius
    }
    public set dropShadowBlurRadius(input: number) {
        this.responsiveModel.dropShadow!.dropShadowBlurRadius = input
    }
}
