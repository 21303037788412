
import type { ChillnnContentsPageModel, Scalars } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class DeleteButton extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) public uuid!: Scalars['ID']

    public deleteBox(): void {
        if (window.confirm('本当に削除しますか？')) {
            this.pageModel.deleteBox(this.uuid)
        }
    }
}
