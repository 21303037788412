
import type { AtomHtmlModel, ChillnnContentsBoxModel, ChillnnContentsPageModel, ChillnnStyleBackgroundModel, ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnStyleFontCommonModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/styles/modules/common/fontCommon'
// component
import Modal from '../index.vue'
import QuestionContents from '../../../functionModule/Organisms/QuestionContents/index.vue'
import QuestionId from '../../../functionModule/Organisms/QuestionID/index.vue'
import QuestionLayer from '../../../functionModule/Organisms/QuestionLayer/index.vue'
import QuestionFontResponsive from '../../../functionModule/Organisms/QuestionFontResponsive/index.vue'
import QuestionFont from '../../../functionModule/Organisms/QuestionFont/index.vue'
@Component({
    components: {
        Modal,
        QuestionContents,
        QuestionId,
        QuestionLayer,
        QuestionFontResponsive,
        QuestionFont,
    },
})
export default class HtmlModal extends Vue {
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel

    public get boxModel(): ChillnnContentsBoxModel {
        return this.atomModel.parentBoxModel as ChillnnContentsBoxModel
    }
    public get atomModel(): AtomHtmlModel {
        return this.pageModel.atomModel as AtomHtmlModel
    }
    public get fontCommonModel(): ChillnnStyleFontCommonModel {
        return this.atomModel.commonStyleModel.font!
    }
    public get backgroundModel(): ChillnnStyleBackgroundModel {
        return this.atomModel.commonStyleModel.background!
    }
    public get responsiveStyleModel(): ResponsiveStyleModel {
        if (this.mode === 'pc') {
            return this.pageModel.atomModel!.pcStyleModel
        } else if (this.mode === 'mobile') {
            return this.pageModel.atomModel!.mobileStyleModel
        }
        throw new Error()
    }
}
