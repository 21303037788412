import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { AnimationUtil } from '@/lib-components/util/animationUtil'
import { AtomHtmlModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomHtml'
import { AtomImageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomImage'
import { AtomLineModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomLine'
import { AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomMap'
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomYoutube'
import { ChillnnContentAtomType, StudioClickEventType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import type {
    ChillnnAtomModel,
    ChillnnContentsAtomHtml,
    ChillnnContentsAtomImage,
    ChillnnContentsAtomInterface,
    ChillnnContentsAtomLine,
    ChillnnContentsAtomMap,
    ChillnnContentsAtomYoutube,
    ChillnnContentsBoxModel,
    ICSS,
} from '@CHILLNN-Inc/chillnn-abr-studio'
// import { StudioClickEventType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsPage'
@Component({})
export class AtomRendererBase extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public parentModel!: ChillnnContentsBoxModel
    @Prop({ required: true }) public atom!: ChillnnContentsAtomInterface
    @Prop({ required: true }) width!: number

    private modelLocal: ChillnnAtomModel | null = null
    public getModel(): ChillnnAtomModel {
        if (this.modelLocal) return this.modelLocal
        switch (this.atom.contentAtomType) {
            case ChillnnContentAtomType.Html:
                this.modelLocal = new AtomHtmlModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomHtml)
                return this.modelLocal
            case ChillnnContentAtomType.Image:
                this.modelLocal = new AtomImageModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomImage)
                return this.modelLocal
            case ChillnnContentAtomType.Map:
                this.modelLocal = new AtomMapModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomMap)
                return this.modelLocal
            case ChillnnContentAtomType.Youtube:
                this.modelLocal = new AtomYoutubeModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomYoutube)
                return this.modelLocal
            case ChillnnContentAtomType.Line:
                this.modelLocal = new AtomLineModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomLine)
                return this.modelLocal
            default:
                throw new Error()
        }
    }
    public atomModel = this.getModel()
    public animationUtil = new AnimationUtil(this.atomModel.animationModel)
    public get pcStyle(): ICSS {
        if (this.width <= 900) {
            return this.atomModel.mobileStyle
        } else {
            return this.atomModel.pcStyle
        }
    }
    public get mobileStyle(): ICSS {
        return this.atomModel.mobileStyle
    }

    public get isLink(): boolean {
        return this.atomModel.linkModel && this.atomModel.linkModel.linkType !== 'None'
    }

    private get isReservationButton(): boolean {
        return RESERVATION_EVENT.includes(this.atomModel?.linkModel?.linkType)
    }

    public get gtmTagClass(): { [key: string]: boolean } {
        return {
            'gtm-reservation-cv-button-step0-snack-inner': this.isReservationButton,
        }
    }

    public click(): void {
        this.atomModel.linkModel.click()
    }
}

const RESERVATION_EVENT = [
    StudioClickEventType.HotelReservation, //
    StudioClickEventType.HotelReservationRoom,
]
