
import { position } from '@/lib-components/editor/models/pageModel'
import { Component, Prop } from 'vue-property-decorator'
// component
import PaddingHandlerButton from '../../modules/paddingHandler/modules/paddingHandlerButton.vue'
import PositionHandler from '../../modules/positionHandler/index.vue'
import PaddingHandlerBase from '../../modules/paddingHandler/base'
import SizeHandlerButton from '../../modules/sizeHandler/modules/sizeHandlerButton.vue'
import type { AtomHtmlModel, ChillnnStyleBorderResponsiveModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { BackgroundType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'

@Component({
    components: {
        PaddingHandlerButton,
        PositionHandler,
        SizeHandlerButton,
    },
})
export default class PaddingHandler extends PaddingHandlerBase {
    @Prop({ required: true }) showHandler!: boolean
    @Prop({ required: true }) positionHandler!: () => void
    @Prop({ required: true }) htmlModel!: AtomHtmlModel
    @Prop({ required: true }) radiusModel!: ChillnnStyleBorderResponsiveModel
    public get isShowRadiusHandler(): boolean {
        return this.htmlModel.commonStyleModel.background!.type !== BackgroundType.TRANSPARENT
    }
    public get isVertical(): boolean {
        if (this.htmlModel.commonStyleModel.font) {
            return this.htmlModel.commonStyleModel.font.isVertical
        } else {
            return false
        }
    }
    // ========================= handler func ============================
    // padding
    public setTopPadding(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingTop = this.spaceModel.paddingTop + diff.y
            this.spaceModel.paddingBottom = this.spaceModel.paddingBottom + diff.y
            this.spaceModel.top = this.spaceModel.top - diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setRightPadding(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingLeft = this.spaceModel.paddingLeft - diff.x
            this.spaceModel.paddingRight = this.spaceModel.paddingRight - diff.x
        }
        this.pageModel.setSetPosition(func)
    }
    public setBottomPadding(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingTop = this.spaceModel.paddingTop - diff.y
            this.spaceModel.paddingBottom = this.spaceModel.paddingBottom - diff.y
            this.spaceModel.top = this.spaceModel.top + diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setLeftPadding(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingLeft = this.spaceModel.paddingLeft + diff.x
            this.spaceModel.paddingRight = this.spaceModel.paddingRight + diff.x
        }
        this.pageModel.setSetPosition(func)
    }
    // width
    public setTop(): void {
        const func = (diff: position) => {
            this.spaceModel.top = this.spaceModel.top + diff.y
            this.sizeModel.similarHeight = this.sizeModel.height - diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setLeftWidth(): void {
        const func = (diff: position) => {
            this.sizeModel.similarWidth = this.sizeModel.width - diff.x
            this.spaceModel.left = this.spaceModel.left + diff.x
            // this.spaceModel.marginLeft = this.spaceModel.marginLeft + diff.x;
        }
        this.pageModel.setSetPosition(func)
    }
    public setBottom(): void {
        const func = (diff: position) => {
            this.sizeModel.similarHeight = this.sizeModel.height + diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setRightWidth(): void {
        const func = (diff: position) => {
            this.sizeModel.similarWidth = this.sizeModel.width + diff.x
        }
        this.pageModel.setSetPosition(func)
    }
}
