
import { ChillnnAtomModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'

@Component({
    components: {
        QuestionTemplate,
    },
})
export default class QuestionLayer extends Vue {
    @Prop({ required: true }) public atomModel!: ChillnnAtomModel

    public toFront(): void {
        this.atomModel.toFront()
    }
    public toBack(): void {
        this.atomModel.toBack()
    }
}
