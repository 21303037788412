import styled from 'vue-styled-components'

const cssObject = {
    pc: Object,
    mobile: Object,
    isLink: Boolean,
}
class StyledComponentFactory {
    public div() {
        return styled('div', cssObject)`
            ${(cssObject) => this.parseObject(cssObject.pc as any)}
            // hover時のanimation
            transition: transform 0.2s linear;
            &:hover {
                ${(cssObject) => this.hoveredObject(cssObject.pc as any, cssObject.isLink as any)}
            }
            @media (max-width: 900px) {
                ${(cssObject) => this.parseObject(cssObject.mobile as any)}
                // hover時のanimation
                &:hover {
                    ${(cssObject) => this.hoveredObject(cssObject.mobile as any, cssObject.isLink as any)}
                }
            }
        `
    }

    // =========================== private ===============================

    private parseObject(input: { [key: string]: string }) {
        return Object.keys(input)
            .map((key) => {
                return `${this.camelToKebab(key)}: ${input[key]};`
            })
            .join('\n')
    }
    private hoveredObject(input: { [key: string]: string }, isLink: boolean) {
        if (isLink) {
            const transform = input.transform || ''
            return `transform: ${transform} scale(1.03);` // hover時に1.03倍になる
        } else {
            return ``
        }
    }
    private camelToKebab(key: string) {
        return key.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
    }
}

const styledComponentFactory = new StyledComponentFactory()
export const StyledDiv = styledComponentFactory.div() as any
