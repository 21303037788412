
import { AtomHtmlModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Watch } from 'vue-property-decorator'
import { AtomBase } from '../../AtomBase'
// component
import PaddingHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/atom/htmlHandler.vue'
import Editor from './editor/index.vue'
import DeleteButton from '../../../HandleButtons/DeleteButtonAtom.vue'
import CopyButton from '../../../HandleButtons/CopyButtonAtom.vue'
import LinkButton from '../../../HandleButtons/LinkButton/index.vue'
// directives
import vClickOutside from 'v-click-outside'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        PaddingHandler,
        Editor,
        // buttons
        DeleteButton,
        CopyButton,
        LinkButton,
    },
})
export default class AtomHtml extends AtomBase {
    public sssss: any = 'sss'
    public get src(): string {
        return (this.atomModel as AtomHtmlModel).src
    }
    public get srcRaw(): string {
        return (this.atomModel as AtomHtmlModel).src.replaceAll('<p></p>', '<br />')
    }
    // ============== focus の判定 ================
    public get wrapperStyle(): ICSS {
        return {
            ...super.style,
            padding: 0,
        }
    }
    // ============== handler ==============
    public setEditor(): void {
        if (this.isSelected) {
            super.showEditor = true
        }
    }
    public removeEditor(): void {
        super.showEditor = false
    }
    @Watch('isSelected')
    public remove(): void {
        if (!this.isSelected) {
            super.showEditor = false
        }
    }
    public dragAndMarginHTML(): void {
        if (!super.showEditor) {
            this.dragAndMargin()
        }
    }
}
