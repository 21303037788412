import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { ChillnnContentsPageVueModel, position } from '../../../models/pageModel';

@Component
export class MouseEventMixin extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public start: position = { x: 0, y: 0 } // 移動開始のポジション
    public diff: position = { x: 0, y: 0 } // 移動距離
    private currentFunc: null | ((diff: position) => void) = null
    // ============== 計測開始 ==============
    public mousedown(e: MouseEvent): void {
        this.resetStart(e)
        if (this.pageModel.setPosition) {
            this.setInitPosition({ ...this.start })
            this.currentFunc = this.pageModel.setPosition
        } else if (this.pageModel.selectedAtomModels.length) {
            // 複数選択されている場合
            this.pageModel.setSetPositionSelectedAtomModels()
            this.setInitPosition({ ...this.start })
            this.currentFunc = this.pageModel.setPosition
        }
    }
    // ============== 計測中 ==============
    public mousemove(e: MouseEvent): void {
        if (this.pageModel.setPosition && !this.initPosition && !this.endPosition) {
            // mousedownのタイミングでsetPositionがうまく起動していなかった場合
            this.setInitPosition({ ...this.start })
        }
        this.throttledMouseMove(e)
    }
    // ============== 計測終了 ==============
    public mouseup(): void {
        this.addHistoryForMouse()
        this.pageModel.setPosition = null
        this.moveStart = false
    }

    // ================================
    // private
    // ================================
    private getDiff(e: MouseEvent): position {
        const current: position = { x: e.x, y: e.y }
        return {
            x: current.x - this.start.x,
            y: current.y - this.start.y,
        }
    }
    private resetStart(e: MouseEvent): void {
        this.start.x = e.x
        this.start.y = e.y
    }
    // ================================
    // throttle & debounce
    // ================================
    private moveStart = false
    private mouseMove(e: MouseEvent): void {
        const diff = this.getDiff(e)
        if (this.pageModel.setPosition) {
            if (!this.moveStart) {
                // クリックした瞬間に少し動いてしまうのを防ぐ
                const root = Math.sqrt(diff.x * diff.x + diff.y * diff.y)
                if (root < 5) {
                    // 30px以上動いたら動く
                    return
                } else {
                    this.moveStart = true
                }
            }
            this.updateEndPosition(diff)
            this.pageModel.setPosition(diff)
        }
        this.resetStart(e)
    }
    private throttle<T extends (...args: any[]) => unknown>(callback: T, delay = 250): (...args: Parameters<T>) => void {
        let lastTime = performance.now() - delay
        return (...args) => {
            if (lastTime + delay < performance.now()) {
                lastTime = performance.now()
                callback(...args)
            }
        }
    }
    private throttledMouseMove = this.throttle(this.mouseMove, 10)

    // =================================
    // command zで戻るやつ
    // =================================
    private get operation() {
        return this.pageModel.operationCoordinator
    }
    /** init position */
    private initPosition: position | null = null
    private endPosition: position | null = null
    private setInitPosition(start: position): void {
        this.initPosition = { ...start }
        this.endPosition = { ...start }
    }
    private updateEndPosition(diff: position): void {
        if (this.endPosition) {
            this.endPosition.x += diff.x
            this.endPosition.y += diff.y
        }
    }
    private addHistoryForMouse(): void {
        if (this.initPosition && this.endPosition) {
            const diff = {
                x: this.endPosition.x - this.initPosition.x,
                y: this.endPosition.y - this.initPosition.y,
            }
            if (!diff.x && !diff.y) {
                // 変化がない場合
                return
            }
            if (this.currentFunc) {
                this.operation.addOperation({
                    operation: () => this.currentFunc!(diff),
                    inverseOperation: () => this.currentFunc!({ x: -diff.x, y: -diff.y }),
                })
            }
        }
        this.endPosition = null
        this.initPosition = null
    }
}
