import { render, staticRenderFns } from "./htmlHandler.vue?vue&type=template&id=dd65c460&scoped=true"
import script from "./htmlHandler.vue?vue&type=script&lang=ts"
export * from "./htmlHandler.vue?vue&type=script&lang=ts"
import style0 from "./htmlHandler.vue?vue&type=style&index=0&id=dd65c460&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd65c460",
  null
  
)

export default component.exports