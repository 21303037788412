
import { ChillnnContentsBox, ChillnnImage, IChillnnTemplatePageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import ChillnnBoxRenderer from '@/lib-components/renderer/components/ChillnnBoxRenderer.vue'
import SnackTemplateContents from './snackTemplateContents.vue'
export type Category = {
    key: string
    value: string
}
@Component({
    components: {
        ChillnnBoxRenderer,
        SnackTemplateContents,
    },
})
export default class SnackTemplateCard extends Vue {
    @Prop({ required: true }) templatePageModel!: IChillnnTemplatePageModel
    public box: ChillnnContentsBox | null = null

    public get thumbnail(): ChillnnImage {
        return this.templatePageModel.thumbnail
    }
    public get title(): string {
        return this.templatePageModel.title
    }
    public get uuid(): string {
        return this.templatePageModel.uuid
    }
    public changeDetail(): void {
        this.$emit('select', this.templatePageModel)
    }
}
