import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { ChillnnStyleSizeModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

// component

@Component({})
export class SizeHandlerBase extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) isSelected!: boolean
    @Prop({ required: true }) sizeModel!: ChillnnStyleSizeModel

    public get style(): ICSS {
        if (this.isSelected) {
            return {
                border: '0 solid rgba(32,209,0,.2)',
                height: `${this.sizeModel.height}px`,
            }
        } else {
            return {}
        }
    }
    public get wrapperClass(): string {
        return this.isSelected ? 'selected' : ''
    }
}
