
import { AtomImageModel, ChillnnImage } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { imageRepository } from './imageRepositorySample'

// component
import SelectedImageItem from './multipleImageCard.vue'
import ImageListItem from './ListItem.vue'
import ImageInput from './input.vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'

@Component({
    components: {
        SelectedImageItem,
        ImageInput,
        ImageListItem,
    },
})
export default class ImageMultipleSelect extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public imageRepository = imageRepository()
    public imageUploader = this.pageModel.imageUploader

    public async getImage(): Promise<void> {
        if (this.imageUploader) {
            // ここで表示するImageの更新
            await this.fetchImages()
        }
    }

    public async created(): Promise<void> {
        // loadする
        await this.fetchImages()
    }

    public async fetchImages(): Promise<void> {
        if (this.pageModel.imageRepository) {
            this.imageRepository = await this.pageModel.imageRepository()
        }
    }

    // ============= v-model =============
    @Prop({ required: true }) imageModel!: AtomImageModel
    public get images(): ChillnnImage[] {
        return this.imageModel.srces
    }
    public imageSelect(src: ChillnnImage): void {
        this.imageModel.srces = [...this.imageModel.srces, src]
    }
    public remove(idx: number): void {
        this.imageModel.srces = this.imageModel.srces.filter((_, idxLocal) => idxLocal !== idx)
    }
}
