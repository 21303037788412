import { AnimationType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'

import type { AnimationModel } from '@CHILLNN-Inc/chillnn-abr-studio'
export class AnimationUtil {
    constructor(private animationModel: AnimationModel) {}

    public get animationAOS(): string | null {
        switch (this.animationModel.animationType) {
            case AnimationType.Fade:
                return 'fade'
            case AnimationType.FadeUp:
                return 'fade-up'
            case AnimationType.FadeRight:
                return 'fade-right'
            case AnimationType.FadeLeft:
                return 'fade-left'
            case AnimationType.ZoomIn:
                return 'zoom-in'
            case AnimationType.ZoomInRight:
                return 'zoom-in-right'
            case AnimationType.ZoomInLeft:
                return 'zoom-in-left'
            default:
                return null
        }
    }

    public get duration(): number {
        return this.animationModel.duration
    }
}
