
import type { AtomImageModel, ChillnnImage, ICSS, ImageType } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Watch } from 'vue-property-decorator'
import { AtomBase } from '../../AtomBase'
// component
import LinkButton from '../../../HandleButtons/LinkButton/index.vue'
import DeleteButton from '../../../HandleButtons/DeleteButtonAtom.vue'
import CopyButton from '../../../HandleButtons/CopyButtonAtom.vue'
import ImageHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/atom/imageHandler.vue'
// directives
import vClickOutside from 'v-click-outside'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        LinkButton,
        DeleteButton,
        CopyButton,
        ImageHandler,
    },
})
export default class AtomIMAGE extends AtomBase {
    public get src(): ChillnnImage {
        return (this.atomModel as AtomImageModel).src
    }
    public get srces(): ChillnnImage[] {
        return (this.atomModel as AtomImageModel).srces
    }
    public get type(): ImageType {
        return (this.atomModel as AtomImageModel).imageType
    }
    public get bg(): ICSS {
        return (this.atomModel as AtomImageModel).backgroundCss
    }

    public get wrapperStyle(): ICSS {
        return {
            ...this.style,
            filter: undefined,
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
        }
    }

    public get boxImageStyle(): ICSS {
        return {
            ...this.style,
            ...this.bg,
            transform: undefined,
            left: 0,
            top: 0,
        }
    }

    public get inlineImageStyle(): ICSS {
        return {
            filter: (this.style && this.style.filter) || undefined,
        }
    }
    public get slideInlineImageStyle(): ICSS {
        return {
            filter: (this.style && this.style.filter) || undefined,
            height: this.style.height,
            objectFit: 'cover',
        }
    }

    public get slideIntervalTime(): number {
        return (this.atomModel as AtomImageModel).slideInterval
    }

    public slideInterval: any

    public mounted(): void {
        this.slide()
    }

    @Watch('type')
    public typeChange(): void {
        clearInterval(this.slideInterval)
        this.slide()
    }

    @Watch('slideIntervalTime')
    public intervalChange(): void {
        clearInterval(this.slideInterval)
        this.slide()
    }

    public slide(): void {
        if (this.type === 'slideshow' && this.slideIntervalTime > 0) {
            this.slideInterval = setInterval(() => {
                const atomImageModel = this.atomModel as AtomImageModel
                atomImageModel.next()
                if (this.type !== 'slideshow') {
                    clearInterval(this.slideInterval)
                }
            }, this.slideIntervalTime)
        } else {
            clearInterval(this.slideInterval)
        }
    }
}
