
import type { ChillnnContentsBox } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsPage/index'
import { ContentsDuplicate } from '@CHILLNN-Inc/chillnn-abr-studio/dist/service/duplicate'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// components
import ChillnnBoxRenderer from '@/lib-components/renderer/components/ChillnnBoxRenderer.vue'

@Component({
    components: {
        ChillnnBoxRenderer,
    },
})
export default class SnackTemplateBoxRenderer extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    @Prop() public box!: ChillnnContentsBox
    @Prop({ required: true }) closeModal!: () => void
    @Prop() public index!: number
    @Prop({ required: true }) public selectedDevice!: 'PC' | 'MOBILE'

    public staticPageModel: ChillnnContentsPageModel = new ChillnnContentsPageModel({
        name: { default: 'static', en: null },
        uuid: 'sample',
        boxes: [],
        commonStyle: {} as any,
    })

    public selectBox(): void {
        const boxes = this.pageModel.boxes
        let uuid = undefined
        if (boxes.length) {
            uuid = boxes[boxes.length - 1].uuid
        }
        const box = ContentsDuplicate.duplicateBox(this.box)
        this.pageModel.insertBox(box, uuid)
        this.closeModal()
    }

    public mounted(): void {
        // heightのリサイズ
        setTimeout(() => {
            const element = document.getElementById(this.box.uuid)!
            setHeight(element)
        }, 0.5)
    }
    public get height(): string {
        if (this.selectedDevice === 'PC') {
            return '270px'
        } else {
            return '500px'
        }
    }
    public get width(): number {
        if (this.selectedDevice === 'PC') {
            return 1440
        } else {
            return 375
        }
    }
    public get snackTemplateBoxRendererClass(): string {
        if (this.selectedDevice === 'PC') {
            return 'snack_template_box_renderer_pc'
        } else {
            return 'snack_template_box_renderer_mobile'
        }
    }
}
function setHeight(element: HTMLElement) {
    const height = element.clientHeight
    element.style.marginBottom = `-${Math.round(height - height * 0 /* scaleの大きさと一緒 */)}px`
}
