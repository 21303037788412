
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class RangePicker extends Vue {
    @Prop({ default: 0 }) public min!: number
    @Prop({ default: 1 }) public max!: number
    @Prop({ default: 0.025 }) public step!: number
    // ==================== v-model ====================
    @Prop() value!: string
    public get localValue(): string {
        return this.value
    }
    public set localValue(input: string) {
        this.$emit('input', input)
    }
}
