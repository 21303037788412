var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image_container",class:{
        isLink: _vm.isLink,
        multipleSelected: _vm.multipleSelected,
        isSimilarWidth: _vm.isSimilarWidth,
        isSimilarHeight: _vm.isSimilarHeight,
        isSimilarTop: _vm.isSimilarTop,
        isSimilarLeft: _vm.isSimilarLeft,
        isSimilarRight: _vm.isSimilarRight,
    },style:(_vm.wrapperStyle),on:{"click":[function($event){$event.stopPropagation();if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.select.apply(null, arguments)},function($event){if(!$event.shiftKey)return null;$event.stopPropagation();if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.shiftSelect.apply(null, arguments)}],"mousedown":_vm.dragAndMargin,"mouseup":_vm.removeDragHandler,"mouseenter":_vm.shiftSelectMouseEnter}},[_c('image-handler',{attrs:{"isSelected":_vm.isSelected,"sizeModel":_vm.responsiveModel.size,"spaceModel":_vm.responsiveModel.space,"pageModel":_vm.pageModel}},[(_vm.type === 'inline')?[_c('img',{staticClass:"image",style:(_vm.inlineImageStyle),attrs:{"src":_vm.src}})]:(_vm.type === 'slideshow')?[_c('transition',{attrs:{"name":"slide-show-list","mode":"out-in"}},[_vm._l((_vm.srces),function(selectedSrc){return [(selectedSrc === _vm.src)?_c('img',{key:selectedSrc,staticClass:"image",style:(_vm.slideInlineImageStyle),attrs:{"src":selectedSrc}}):_vm._e()]})],2)]:[_c('div',{style:({ ..._vm.boxImageStyle })})]],2),(_vm.isSelected)?_c('div',{staticClass:"modal_container right",style:({ ..._vm.buttonRotate })},[_c('link-button',{attrs:{"pageModel":_vm.pageModel,"linkModel":_vm.linkModel}}),_c('copy-button',{on:{"copy":_vm.copy}}),_c('delete-button',{on:{"click":_vm.deleteSelf}})],1):_vm._e(),(_vm.type === 'slideshow')?[_c('div',{staticClass:"slideshow_navi_container"},_vm._l((_vm.srces),function(navi,index){return _c('div',{key:navi,staticClass:"slideshow_navi",class:{ slideshow_navi_selected: navi === _vm.src },on:{"click":function($event){return _vm.atomModel.selectImage(index)}}})}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }