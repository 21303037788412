
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnStyleFontCommonModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/styles/modules/common/fontCommon'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import type { ChillnnStyleBackgroundModel, ChillnnImage, AnimationModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { BackgroundType, WritingMode } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import FontPicker from '../../Molecule/FontPicker/index.vue'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import RangePicker from '../../Atom/rangePicker.vue'
import ImagePicker from '../../Molecule/ImagePicker/index.vue'
import AnimationSelect from '../../Molecule/AnimationSelect/index.vue'
import TextInput from '../../Atom/textInput.vue'

@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        ColorPicker,
        FontPicker,
        SelectBox,
        RangePicker,
        ImagePicker,
        AnimationSelect,
        TextInput,
    },
})
export default class QuestionFont extends Vue {
    @Prop({ required: true }) fontCommonModel!: ChillnnStyleFontCommonModel

    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public backgroundModel!: ChillnnStyleBackgroundModel
    public readonly kv: SelectItem[] = [
        {
            key: '透明にする',
            value: BackgroundType.TRANSPARENT,
        },
        {
            key: '背景色を指定する',
            value: BackgroundType.COLOR,
        },
        {
            key: '背景画像を指定する',
            value: BackgroundType.IMG,
        },
    ]
    public imageSetter(src: ChillnnImage): void {
        this.backgroundModel.image = src
    }
    public readonly writingModes: SelectItem[] = [
        {
            key: '横書き',
            value: WritingMode.initial,
        },
        {
            key: '縦書き',
            value: WritingMode.vertical_rl,
        },
    ]

    @Prop({ required: true }) animationModel!: AnimationModel
}
