
import { Component } from 'vue-property-decorator'
import { AtomRendererBase } from '../base'
import type { AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { MapType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
// component
import { StyledDiv } from '../../../common/StyledComponentFactory'
@Component({
    components: {
        StyledDiv,
    },
})
export default class MapRenderer extends AtomRendererBase {
    public get src(): AtomMapModel {
        return this.atomModel as AtomMapModel
    }

    public get googleMapLink(): string {
        switch ((this.atomModel as AtomMapModel).mapType) {
            case MapType.ADDRESS:
                return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAdBEmdyqDVBOJVirt5FwS7ztn_gFmFIKU&q=${encodeURIComponent(`${(this.atomModel as AtomMapModel).address}`)}&zoom=${
                    (this.atomModel as AtomMapModel).scale || 15
                }`
            case MapType.GEOLOCATION:
                return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAdBEmdyqDVBOJVirt5FwS7ztn_gFmFIKU&q=${(this.atomModel as AtomMapModel).geoLocation.lat},${
                    (this.atomModel as AtomMapModel).geoLocation.lon
                }&zoom=${(this.atomModel as AtomMapModel).scale || 15}`
            default:
                throw new Error()
        }
    }
}
