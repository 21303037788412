
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import type {
    ChillnnContentsBoxModel,
    ChillnnAtomModel,
    ChillnnContentsPage,
    ChillnnImage,
    StudioClickEventType,
    IChillnnTemplateModelRepository,
    IChillnnTemplateCategoryModel,
    StudioImageMast,
    Scalars,
    ImageMast,
} from '@CHILLNN-Inc/chillnn-abr-studio'
import { ContentsDuplicate } from '@CHILLNN-Inc/chillnn-abr-studio/dist/service/duplicate'
import { PC_BASE_WIDTH, SP_BASE_WIDTH } from '@CHILLNN-Inc/chillnn-abr-studio/dist'
import { ChillnnContentsPageVueModel, EditorMode } from './models/pageModel'
import type { OtherResource } from '@CHILLNN-Inc/chillnn-abr-studio'
import { EventListenerHandler } from './eventListeners'
// component
import LeftMenu from './components/menu/leftmenu/index.vue'
import ChillnnContentsPageComponent from './components/resources/ChillnnContentsPage/index.vue'
import SettingModal from './components/menu/rightmenu/modal/modalRouter.vue'
import ImageModal from './components/menu/centermenu/modules/ImageModal/index.vue'
import LinkModal from './components/menu/centermenu/modules/LinkModal/index.vue'
import HandlerWrapper from './components/handler/HandlerWrapper.vue'
// animation
import AOS from 'aos'
import 'aos/dist/aos.css'

import { LEFT_SIDE_MENU_TEXT_SAVE, LEFT_SIDE_MENU_TEXT_SAVE_AND_EXIT } from './components/menu/leftmenu/index.vue'

const PC_SCALE = 1050.0 / PC_BASE_WIDTH
const MOBILE_SCALE = 400.0 / SP_BASE_WIDTH

@Component({
    components: {
        LeftMenu,
        ChillnnContentsPageComponent,
        SettingModal,
        ImageModal,
        LinkModal,
        HandlerWrapper,
    },
})
export default class ChillnnStudioEditor extends Vue {
    @Prop({ required: true }) page!: ChillnnContentsPage // ページのjson
    @Prop() imageRepository!: () => Promise<StudioImageMast[]> // 画像を取得するための関数
    @Prop() imageUploader!: (image: File) => Promise<ChillnnImage> // 画像をアップロードするための関数
    @Prop() imageDeleter!: (uuid: Scalars['ID']) => Promise<ImageMast> // 画像を消去するための関数
    @Prop({ default: () => [] }) availableClickEvents!: StudioClickEventType[] // 利用可能なクリックイベント
    @Prop({ default: false }) isShowMultiLang!: boolean
    // 流し込むリソース
    @Prop({ default: () => [] }) pageResources!: OtherResource[] // 他のページへのリンク
    @Prop({ default: () => [] }) hotelPlanResources!: OtherResource[] //
    @Prop({ default: () => [] }) hotelRoomResources!: OtherResource[] //
    // ページテンプレート
    @Prop({ default: () => [] }) templates!: IChillnnTemplateCategoryModel
    @Prop({ default: () => [] }) parentTemplates!: IChillnnTemplateCategoryModel
    @Prop({ required: true }) snackTemplates!: IChillnnTemplateModelRepository

    /**
     * 左側のサイドメニューの「保存して終了」ボタンが押された時のコールバック関数
     */
    @Prop({ default: undefined }) callbackSaveAndExit!: () => Promise<void> | undefined
    /**
     * 左側のサイドメニューの「一時保存」ボタンが押された時のコールバック関数
     */
    @Prop({ default: undefined }) callbackSave!: () => Promise<void> | undefined
    /**
     * コールバック関数
     */
    @Prop({ default: undefined }) callbackPreview!: () => Promise<void> | undefined

    public pageModel = new ChillnnContentsPageVueModel(this.page, 'mobile', {
        scale: MOBILE_SCALE, // 900 / 1440
        imageRepository: this.imageRepository,
        imageUploader: this.imageUploader,
        imageDeleter: this.imageDeleter,
        availableClickEvents: this.availableClickEvents,
        isShowMultiLang: this.isShowMultiLang,
        // resources
        pageResources: this.pageResources,
        hotelPlanResources: this.hotelPlanResources,
        hotelRoomResources: this.hotelRoomResources,
        // template
        templates: this.templates,
        snackTemplates: this.snackTemplates,
        // parent template
        parentTemplates: this.parentTemplates,
    })

    // ==========================================
    //
    // box
    //
    // ==========================================
    public selectedBoxModel: ChillnnContentsBoxModel | null = null
    public selectedAtomModel: ChillnnAtomModel | null = null
    private setBox(): void {
        this.selectedBoxModel = this.pageModel.boxModel
    }
    private setAtom(): void {
        this.selectedAtomModel = this.pageModel.atomModel
    }
    // ==========================================
    //
    // save
    //
    // ==========================================
    public async save(input: string): Promise<void> {
        if (input === LEFT_SIDE_MENU_TEXT_SAVE_AND_EXIT) {
            // 「保存して終了」が押された場合
            this.callbackSaveAndExit !== undefined && (await this.callbackSaveAndExit())
        } else if (input === LEFT_SIDE_MENU_TEXT_SAVE) {
            // 「一時保存」が押された場合
            this.callbackSave !== undefined && (await this.callbackSave())
        }

        this.$emit('save', input)
    }
    // ==========================================
    //
    // preview
    //
    // ==========================================
    public async preview(): Promise<void> {
        this.$emit('preview')
        this.callbackPreview() !== undefined && (await this.callbackPreview())
    }
    // ==========================================
    //
    // responsive
    //
    // ==========================================
    public get mode(): EditorMode {
        return this.pageModel.mode
    }
    public modeChanger(mode: EditorMode): void {
        // scale変更
        if (mode === 'pc') {
            this.pageModel.setScale(PC_SCALE)
        } else if (mode === 'mobile') {
            this.pageModel.setScale(MOBILE_SCALE)
        }
        this.pageModel.mode = mode
    }
    // ==========================================
    //
    // 初期設定
    //
    // ==========================================
    public created(): void {
        AOS.init({
            offset: 0,
        }) // animationの初期化
        // model observer
        this.page.boxes = this.page.boxes.map((item) => ContentsDuplicate.duplicateBox(item))
        this.pageModel.setBoxObserver(this.setBox) // boxのobserverのset
        this.pageModel.setAtomObserver(this.setAtom) // atomのobserverのset
    }
    // ==========================================
    //
    // イベントリスナー
    //
    // ==========================================
    public mounted(): void {
        const eventListener = new EventListenerHandler(this.pageModel)
        eventListener.deleteHandler()
        eventListener.backHandler()
        eventListener.navigationGuard()
    }
}
