
import { ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class TextInput extends Vue {
    @Prop({ default: null }) public inputWidth!: number
    @Prop({ default: 'text' }) type!: 'text' | 'number'
    @Prop({ default: null }) step!: number
    @Prop() label!: string
    @Prop() unitName!: string
    @Prop({ required: true }) value!: string | number
    @Prop({ default: () => '' }) placeHolder!: string
    public get localValue(): string | number {
        return this.value
    }
    public set localValue(input: string | number) {
        this.$emit('input', input)
    }
    public get inputStyle(): ICSS {
        if (this.inputWidth) {
            return {
                width: `${this.inputWidth}px`,
            }
        } else {
            return {}
        }
    }
}
