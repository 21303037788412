
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import AppRadioButtonListItem from './ListItem.vue'
import SelectBox from '../SelectBox/index.vue'
import { fontRepository } from './fontRepositorySample'

export type KeyValueObject = {
    key: string
    value: string
}

@Component({
    components: {
        SelectBox,
        AppRadioButtonListItem,
    },
})
export default class AppRadioButtonList extends Vue {
    @Prop() public value!: string | number | null
    @Prop() public name!: string
    @Prop({ default: false }) public flowRadioBox!: boolean
    @Prop({ default: false }) public twoColumn!: boolean

    public dataSets: KeyValueObject[] = fontRepository()

    public get Mode(): string | null {
        if (this.flowRadioBox !== false) {
            return 'radio_box_list'
        } else if (this.twoColumn !== false) {
            return 'two_culum'
        }
        return null
    }

    public get localValue(): string | number | null {
        return this.value || null
    }

    public set localValue(value: string | number | null) {
        this.$emit('input', value)
    }
}
