
import { ChillnnImage } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { getImages, HTMLInputEvent } from '@/lib-components/util/imageUtil'

@Component({})
export default class ImagePickerInput extends Vue {
    @Prop({ required: true }) imageUploader!: (image: File) => Promise<ChillnnImage>
    public async getImageFile(e: HTMLInputEvent): Promise<void> {
        const files = await getImages(e)
        for (const file of files) {
            if (this.imageUploader) {
                const chillnnImage = await this.imageUploader(file.file)
                this.$emit('image-update', chillnnImage)
            } else {
                this.$emit('image-update', file.base64)
            }
        }
    }
}
