
import { Component, Prop } from 'vue-property-decorator'
// component
import SizeHandlerButton from '../../modules/sizeHandler/modules/sizeHandlerButton.vue'
import { SizeHandlerBase } from '../../modules/sizeHandler/base'
import { position } from '@/lib-components/editor/models/pageModel'
import { ChillnnStyleSpaceModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'

@Component({
    components: {
        SizeHandlerButton,
    },
})
export default class SizeHandler extends SizeHandlerBase {
    @Prop({ required: true }) public spaceModel!: ChillnnStyleSpaceModel
    public setTop(): void {
        const func = (diff: position) => {
            this.spaceModel.top = this.spaceModel.top + diff.y
            this.sizeModel.similarHeight = this.sizeModel.height - diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setRight(): void {
        const func = (diff: position) => {
            this.sizeModel.similarWidth = this.sizeModel.width + diff.x
        }
        this.pageModel.setSetPosition(func)
    }
    public setBottom(): void {
        const func = (diff: position) => {
            this.sizeModel.similarHeight = this.sizeModel.height + diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setLeft(): void {
        const func = (diff: position) => {
            this.spaceModel.left = this.spaceModel.left + diff.x
            this.sizeModel.similarWidth = this.sizeModel.width - diff.x
        }
        this.pageModel.setSetPosition(func)
    }

    public get style(): ICSS {
        if (this.isSelected) {
            return {
                border: '0 solid rgba(32,209,0,.2)',
                height: `${this.sizeModel.height}px`,
            }
        } else {
            return {
                // border: '0 solid rgba(32,209,0,.2)',
                height: `${this.sizeModel.height}px`,
            }
        }
    }
}
