
import type { AnimationModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import SelectBox, { SelectItem } from '../SelectBox/index.vue'
import { AnimationType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'

@Component({
    components: {
        SelectBox,
    },
})
export default class AnimationSelect extends Vue {
    @Prop({ required: true }) animationModel!: AnimationModel
    public readonly dataSet: SelectItem[] = [
        {
            key: 'なし',
            value: AnimationType.None,
        },
        {
            key: 'フェード',
            value: AnimationType.Fade,
        },
        {
            key: 'フェードアップ',
            value: AnimationType.FadeUp,
        },
        {
            key: 'ズームイン',
            value: AnimationType.ZoomIn,
        },
    ]
}
