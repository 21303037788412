
import { Component } from 'vue-property-decorator'
import { AtomRendererBase } from './base'
import type { AtomImageModel, ChillnnImage, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ImageType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
// component
import { StyledDiv } from '../../common/StyledComponentFactory'
@Component({
    components: {
        StyledDiv,
    },
})
export default class ImageRenderer extends AtomRendererBase {
    public isHovereImg = false
    public get src(): ChillnnImage {
        return (this.atomModel as AtomImageModel).src
    }
    public get srces(): ChillnnImage[] {
        return (this.atomModel as AtomImageModel).srces
    }
    public get srcBG(): ChillnnImage | null {
        if (this.type === 'inline' || this.type === ImageType.color || !this.src) {
            return null
        } else {
            const atom = this.atomModel as AtomImageModel
            return atom.src
        }
    }
    public get type(): ImageType {
        return (this.atomModel as AtomImageModel).imageType
    }
    public get slideIntervalTime(): number {
        return (this.atomModel as AtomImageModel).slideInterval
    }
    public get bg(): ICSS {
        return (this.atomModel as AtomImageModel).backgroundCss
    }
    public get pcRadius(): ICSS {
        return {
            ...((this.atomModel.pcStyleModel.border && this.atomModel.pcStyleModel.border.style) || {}),
            borderTop: undefined,
            borderRight: undefined,
            borderBottom: undefined,
            borderLeft: undefined,
        }
    }
    public get spRadius(): ICSS {
        return {
            ...((this.atomModel.mobileStyleModel.border && this.atomModel.mobileStyleModel.border.style) || {}),
            borderTop: undefined,
            borderRight: undefined,
            borderBottom: undefined,
            borderLeft: undefined,
        }
    }
    public get slideImageStyle(): any {
        if (document.body.clientWidth < 900) {
            return {
                height: this.mobileStyle.height,
                objectFit: 'cover',
            }
        } else {
            return {
                height: this.pcStyle.height,
                objectFit: 'cover',
            }
        }
    }
    public created(): void {
        this.preload()
        this.slide()
    }
    public get atomImageModel(): AtomImageModel {
        return this.atomModel as AtomImageModel
    }
    /**
     * スライドショーの画像を事前にloadする
     */
    public preload(): void {
        if (this.type === 'slideshow') {
            this.atomImageModel.srces.forEach((image) => {
                try {
                    const url = image as string
                    let imgTag = document.createElement('img')
                    imgTag.src = url
                    // eslint-disable-next-line no-empty
                } catch (err) {}
            })
        }
    }
    /**
     * スライドショーを実行する
     */
    public slide(): void {
        if (this.type === 'slideshow' && this.slideIntervalTime !== 0) {
            setInterval(() => {
                this.atomImageModel.next()
            }, this.slideIntervalTime)
        }
    }
}
