
import { IChillnnTemplateCategoryModel, IChillnnTemplatePageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
// component
import CategoryButton from './modules/header/categoryButton.vue'
import SnackTemplateCard from './modules/contents/snackTemplateCard.vue'
import SnackTemplateDetail from './modules/contents/snackContentsDetail.vue'

export type Category = {
    key: string
    value: string
}

@Component({
    components: {
        CategoryButton,
        SnackTemplateCard,
        SnackTemplateDetail,
    },
})
export default class ChillnnBoxRepository extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) closeModal!: () => void
    public selectedSnackCategory: Category = this.snackCategories[0]

    public get isShowParentTemplate(): boolean {
        return !!this.pageModel?.parentTemplates?.fetchTemplates?.length || false
    }
    // ========================================
    //
    // カテゴリー選択ロジック(新テンプレート)
    //
    // ========================================
    /**
     * カテゴリ一覧を取得
     */
    public get snackCategories(): Category[] {
        return this.pageModel.snackTemplates.fetchTemplateCategories.map((category) => {
            return { key: category.uuid, value: category.title }
        })
    }
    /**
     * カテゴリを選択
     */
    public selectSnackCategory(category: Category): void {
        this.selectedSnackCategory = category
    }
    /**
     * マイページ
     */
    public readonly mypage: Category = {
        key: 'mypage',
        value: 'マイテンプレート',
    }

    /**
     * 共通ページ
     */
    public readonly commonpage: Category = {
        key: 'commonpage',
        value: '共通テンプレート',
    }

    /**
     * スナックのページモデル
     */
    public get selectedPageTemplates(): IChillnnTemplatePageModel[] | null {
        if (this.selectedSnackRepository) {
            return this.selectedSnackRepository.fetchTemplates
        } else {
            return []
        }
    }
    /**
     * 選択されたカテゴリのリポジトリを取得
     */
    private get selectedSnackRepository(): IChillnnTemplateCategoryModel | undefined {
        if (this.selectedSnackCategory.key === this.mypage.key) {
            return this.pageModel.templates
        } else if (this.selectedSnackCategory.key === this.commonpage.key) {
            return this.pageModel.parentTemplates
        } else {
            return this.pageModel.snackTemplates.fetchTemplateCategories.find((item) => {
                return item.uuid === this.selectedSnackCategory.key
            })
        }
    }
    // ========================================
    //
    // 詳細選択ロジック
    //
    // ========================================
    public selectedTemplateModel: IChillnnTemplatePageModel | null = null
    public setTemplate(input: IChillnnTemplatePageModel | null): void {
        this.selectedTemplateModel = input
    }

    // ========================================
    //
    // Life Cycle
    //
    // ========================================
    public created(): void {
        try {
            if (this.pageModel.templates?.fetchTemplates?.length) {
                this.selectedSnackCategory = this.mypage
            } else if (this.pageModel.parentTemplates?.fetchTemplates?.length) {
                this.selectedSnackCategory = this.commonpage
            }
            // eslint-disable-next-line no-empty
        } catch (err) {}
    }
}
