
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
@Component({})
export default class PaddingHandlerButton extends Vue {
    @Prop({ required: true }) direction!: 'vertical' | 'horizontal'
    @Prop({ required: true }) spaceStyle!: any
    public mousedown(): void {
        this.$emit('select')
    }
}
