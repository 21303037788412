
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsPageVueModel, EditorMode } from '@/lib-components/editor/models/pageModel'
// component
import ChillnnContentsBoxComponent from '../ChillnnContentsBox/index.vue'
// directives
import vClickOutside from 'v-click-outside'
import { ChillnnContentsBoxModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsBox'

@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        ChillnnContentsBoxComponent,
    },
})
export default class ChillnnContentsPageComponent extends Vue {
    @Prop({ required: true }) mode!: EditorMode
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public get boxes(): ChillnnContentsBoxModel[] {
        return this.pageModel.boxes.map((item) => new ChillnnContentsBoxModel(this.pageModel, item))
    }
}
