
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { AnimationModel, AtomLineModel, ChillnnStyleSizeModel, ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { CHillnnStyleTransformModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/styles/modules/responsive/transform'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import SelectBox from '../../Molecule/SelectBox/index.vue'
import AppInput from '../../Atom/textInput.vue'
import TextAlign from '../../Atom/textAlign.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import DropShadow from '../../Molecule/DropShadowInput.vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        AppInput,
        TextAlign,
        ColorPicker,
        DropShadow,
    },
})
export default class QuestionLine extends Vue {
    @Prop({ required: true }) responsiveModel!: ResponsiveStyleModel
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) atomLineModel!: AtomLineModel
    @Prop({ required: true }) animationModel!: AnimationModel
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel

    public get statement(): string {
        if (this.mode === 'pc') {
            return 'PCのライン設定'
        } else if (this.mode === 'mobile') {
            return 'モバイルのライン設定'
        }
        return ''
    }
    public get transform(): CHillnnStyleTransformModel | null {
        return this.responsiveModel.transform
    }
    public get size(): ChillnnStyleSizeModel | null {
        return this.responsiveModel.size
    }
    public get rgba(): string {
        if (this.mode === 'pc') {
            return this.atomLineModel.rgbaPc
        } else {
            return this.atomLineModel.rgbaMobile
        }
    }
    public get color(): string {
        if (this.mode === 'pc') {
            return this.atomLineModel.lineColorPc
        } else {
            return this.atomLineModel.lineColorMobile
        }
    }
    public set color(input: string) {
        if (this.mode === 'pc') {
            this.atomLineModel.lineColorPc = input
        } else {
            this.atomLineModel.lineColorMobile = input
        }
    }
    public get opacity(): number {
        if (this.mode === 'pc') {
            return this.atomLineModel.lineOpacityPc
        } else {
            return this.atomLineModel.lineOpacityMobile
        }
    }
    public set opacity(input: number) {
        if (this.mode === 'pc') {
            this.atomLineModel.lineOpacityPc = input
        } else {
            this.atomLineModel.lineOpacityMobile = input
        }
    }
    public get gap(): number {
        if (this.mode === 'pc') {
            return this.atomLineModel.lineGapPc
        } else {
            return this.atomLineModel.lineGapMobile
        }
    }
    public set gap(input: number) {
        if (this.mode === 'pc') {
            this.atomLineModel.lineGapPc = input
        } else {
            this.atomLineModel.lineGapMobile = input
        }
    }
}
