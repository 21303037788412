
import type { AtomYoutubeModel, ChillnnContentsBoxModel, ChillnnContentsPageModel, ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import Modal from '../index.vue'
import QuestionContents from '../../../functionModule/Organisms/QuestionContents/index.vue'
import QuestionId from '../../../functionModule/Organisms/QuestionID/index.vue'
import QuestionLayer from '../../../functionModule/Organisms/QuestionLayer/index.vue'
import QuestionYoutube from '../../../functionModule/Organisms/QuestionYoutube/index.vue'
import QuestionDropShadow from '../../../functionModule/Organisms/QuestionDropShadow/index.vue'
@Component({
    components: {
        Modal,
        QuestionContents,
        QuestionId,
        QuestionLayer,
        QuestionYoutube,
        QuestionDropShadow,
    },
})
export default class MapModal extends Vue {
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    public get boxModel(): ChillnnContentsBoxModel {
        return this.atomModel.parentBoxModel as ChillnnContentsBoxModel
    }
    public get atomModel(): AtomYoutubeModel {
        return this.pageModel.atomModel as AtomYoutubeModel
    }
    public get responsiveStyleModel(): ResponsiveStyleModel {
        if (this.mode === 'pc') {
            return this.pageModel.atomModel!.pcStyleModel
        } else if (this.mode === 'mobile') {
            return this.pageModel.atomModel!.mobileStyleModel
        }
        throw new Error()
    }
}
