
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import type { AnimationModel, AtomImageModel, ChillnnImage } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ImageType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import TextInput from '../../Atom/textInput.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import ImagePicker from '../../Molecule/ImagePicker/index.vue'
import ImagePickerMultiple from '../../Molecule/ImagePicker/multipleSelect.vue'
import AnimationSelect from '../../Molecule/AnimationSelect/index.vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        TextInput,
        ColorPicker,
        ImagePicker,
        ImagePickerMultiple,
        AnimationSelect,
    },
})
export default class ImageTypeSelect extends Vue {
    @Prop({ required: true }) atomImageModel!: AtomImageModel
    @Prop({ required: true }) animationModel!: AnimationModel
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) imageSetter!: (src: ChillnnImage) => void

    public readonly kv: SelectItem[] = [
        {
            key: '全体を表示する',
            value: ImageType.inline,
        },
        {
            key: 'サイズに合わせてトリミングする',
            value: ImageType.background,
        },
        {
            key: '色を選択する',
            value: ImageType.color,
        },
        {
            key: 'スライドショーにする',
            value: ImageType.slideshow,
        },
    ]
}
