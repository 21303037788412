
import type { ChillnnContentsPage, ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
// components
import ChillnnPageRenderer from '@/lib-components/renderer/components/ChillnnPageRenderer.vue'
@Component({
    components: {
        ChillnnPageRenderer,
    },
})
export default class SnackTemplatePageRenderer extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    @Prop() public contentsPage!: ChillnnContentsPage
    @Prop({ required: true }) closeModal!: () => void
    @Prop({ required: true }) public selectedDevice!: 'PC' | 'MOBILE'

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public eventHandler(): void {}

    public get width(): number {
        if (this.selectedDevice === 'PC') {
            return 1440
        } else {
            return 375
        }
    }
    public get snackTemplatePageRendererClass(): string {
        if (this.selectedDevice === 'PC') {
            return 'snack_template_page_renderer_pc'
        } else {
            return 'snack_template_page_renderer_mobile'
        }
    }

    @Emit('adaptTemplate')
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public adaptTemplate(): void {}

    public mounted(): void {
        // heightのリサイズ
        setTimeout(() => {
            const element = document.getElementById(this.contentsPage.uuid)!
            setHeight(element)
        }, 0.5)
    }
}
function setHeight(element: HTMLElement) {
    const height = element.clientHeight
    element.style.marginBottom = `-${Math.round(height - height * 0.4 /* scaleの大きさと一緒 */)}px`
}
