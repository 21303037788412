
import { Component } from 'vue-property-decorator'
import { AtomRendererBase } from './base'
//
import { StyledDiv } from '../../common/StyledComponentFactory'
import { AtomHtmlModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'

@Component({
    components: {
        StyledDiv,
    },
})
export default class HtmlRenderer extends AtomRendererBase {
    public get src(): string {
        return (this.atomModel as AtomHtmlModel).src.replaceAll('<p></p>', '<br />')
    }

    public get customPC(): ICSS {
        return {
            ...this.pcStyle,
            padding: undefined,
        }
    }
    public get customMobile(): ICSS {
        return {
            ...this.mobileStyle,
            padding: undefined,
        }
    }
    public get paddingPC(): ICSS {
        return {
            padding: this.pcStyle.padding,
        }
    }
    public get paddingMobile(): ICSS {
        return {
            padding: this.mobileStyle.padding,
        }
    }
}
