
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component } from 'vue-property-decorator'
import { AtomBase } from '../../../AtomBase'
// component
import DeleteButton from '../../../../HandleButtons/DeleteButtonAtom.vue'
import CopyButton from '../../../../HandleButtons/CopyButtonAtom.vue'
import YoutubeHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/atom/iframeHandler.vue'
// directives
import vClickOutside from 'v-click-outside'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        DeleteButton,
        CopyButton,
        YoutubeHandler,
    },
})
export default class AtomYoutube extends AtomBase {
    public model = this.getModel() as AtomYoutubeModel
    public get youtubeLink(): string {
        return `https://www.youtube.com/embed/${this.model.videoID}?autoplay=${this.model.autoPlay ? '1' : '0'}&loop=1&modestbranding=1`
    }

    public get width(): string {
        return `${this.responsiveModel!.size!.width}px`
    }
    public get height(): string {
        return `${this.responsiveModel!.size!.height}px`
    }
}
