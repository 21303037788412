
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import ListItem from './ListItem.vue'
import colorPickerPrd from '@caohenghu/vue-colorpicker'
import vClickOutside from 'v-click-outside'
import { ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        ListItem,
        colorPickerPrd,
    },
})
export default class ColorPicker extends Vue {
    public isShow = false
    public toggle(): void {
        this.isShow = !this.isShow
    }
    public get currentColor(): ICSS {
        return {
            backgroundColor: this.rgba,
        }
    }

    public readonly colors: string[] = [
        '#ffffff',
        '#f2f2f2',
        '#e0e0e0',
        '#828282',
        '#4f4f4f',
        '#333333',
        '#EB5757',
        '#f2994a',
        '#f2c94c',
        '#219653',
        '#27ae60',
        '#6fcf97',
        '#2f80ed',
        '#2d9cdb',
        '#56ccf2',
        '#9b51e0',
        '#bb6bd9',
    ]
    public changeColor(color: { hex: string; rgba: { a: number } }): void {
        this.colorSetterLocal = color.hex
        this.opacitySetterLocal = color.rgba.a
    }
    // ==================== v-model ====================
    @Prop({ required: true }) rgba!: string
    @PropSync('colorSetter') colorSetterLocal!: string
    @PropSync('opacitySetter') opacitySetterLocal!: number
}
