import { crossKeyHandler } from '@/lib-components/editor/eventListeners/crossKeyHandler'
import { AtomHtmlVueModel } from '@/lib-components/editor/models/atoms/htmlModel'
import { ChillnnContentsPageVueModel, EditorMode, position } from '@/lib-components/editor/models/pageModel'
import { AnimationUtil } from '@/lib-components/util/animationUtil'
import { AtomImageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomImage'
import { AtomLineModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomLine'
import { AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomMap'
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomYoutube'
import { ChillnnContentAtomType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import type {
    ChillnnContentsAtomHtml,
    ChillnnContentsAtomImage,
    ChillnnContentsAtomMap,
    ChillnnContentsAtomInterface,
    ChillnnAtomModel,
    ICSS,
    ResponsiveStyleModel,
    ChillnnContentsBoxModel,
    ChillnnContentsAtomYoutube,
    LinkModel,
    ChillnnContentsAtomLine,
} from '@CHILLNN-Inc/chillnn-abr-studio'

@Component({})
export class AtomBase extends Vue {
    @Prop({ required: true }) mode!: EditorMode
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public parentModel!: ChillnnContentsBoxModel
    @Prop({ required: true }) public atom!: ChillnnContentsAtomInterface

    private modelLocal: ChillnnAtomModel | null = null
    public getModel(): ChillnnAtomModel {
        if (this.modelLocal) return this.modelLocal
        switch (this.atom.contentAtomType) {
            case ChillnnContentAtomType.Html:
                this.modelLocal = new AtomHtmlVueModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomHtml)
                return this.modelLocal
            case ChillnnContentAtomType.Image:
                this.modelLocal = new AtomImageModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomImage)
                return this.modelLocal
            case ChillnnContentAtomType.Map:
                this.modelLocal = new AtomMapModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomMap)
                return this.modelLocal
            case ChillnnContentAtomType.Youtube:
                this.modelLocal = new AtomYoutubeModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomYoutube)
                return this.modelLocal
            case ChillnnContentAtomType.Line:
                this.modelLocal = new AtomLineModel(this.pageModel, this.parentModel, this.atom as ChillnnContentsAtomLine)
                return this.modelLocal
            default:
                throw new Error()
        }
    }

    public atomModel = this.getModel()
    public animationUtil = new AnimationUtil(this.atomModel.animationModel)

    public get linkModel(): LinkModel {
        return this.atomModel.linkModel
    }

    public get style(): ICSS {
        if (this.mode === 'pc') {
            return this.getModel().pcStyle
        } else if (this.mode === 'mobile') {
            return this.getModel().mobileStyle
        } else {
            return {}
        }
    }

    public get responsiveModel(): ResponsiveStyleModel | null {
        if (this.mode === 'pc') {
            return this.atomModel.pcStyleModel
        } else if (this.mode === 'mobile') {
            return this.atomModel.mobileStyleModel
        }
        return null
    }

    public dragAndMargin(): void {
        if (!this.multipleSelected) {
            this.pageModel.atomModel = this.atomModel
            const func = this.getDragFunc()
            this.pageModel.setSetPosition(func)
        }
    }
    public removeDragHandler(): void {
        this.pageModel.setPosition = null
    }

    private getDragFunc() {
        const spaceModel = this.responsiveModel!.space!
        return (diff: position) => {
            spaceModel.similarTop = spaceModel.top + diff.y
            if (this.style.writingMode === 'vertical-rl') {
                // 縦書き
                spaceModel.similarRight = spaceModel.right - diff.x
            } else {
                // 横書き
                spaceModel.similarLeft = spaceModel.left + diff.x
            }
            this.getCoordinate(diff)
        }
    }
    private getDragFuncCross() {
        const spaceModel = this.responsiveModel!.space!
        return (diff: position) => {
            spaceModel.top = spaceModel.top + diff.y
            this.getCoordinate(diff)
            if (this.style.writingMode === 'vertical-rl') {
                // 縦書き
                spaceModel.right = spaceModel.right - diff.x
            } else {
                // 横書き
                spaceModel.left = spaceModel.left + diff.x
            }
        }
    }

    private getCoordinate(diff: position) {
        if (this.getModel().atomType === ChillnnContentAtomType.Line) {
            const atomModel = this.atomModel as AtomLineModel
            if (this.mode === 'pc') {
                //
                atomModel.coordinate1Pc.X = atomModel.coordinate1Pc.X + diff.x
                //
                atomModel.coordinate1Pc.Y = atomModel.coordinate1Pc.Y + diff.y
                atomModel.coordinate2Pc.X = atomModel.coordinate2Pc.X + diff.x
                atomModel.coordinate2Pc.Y = atomModel.coordinate2Pc.Y + diff.y
            } else {
                atomModel.coordinate1Mobile.X = atomModel.coordinate1Mobile.X + diff.x
                atomModel.coordinate1Mobile.Y = atomModel.coordinate1Mobile.Y + diff.y
                atomModel.coordinate2Mobile.X = atomModel.coordinate2Mobile.X + diff.x
                atomModel.coordinate2Mobile.Y = atomModel.coordinate2Mobile.Y + diff.y
            }
        }
    }

    public get buttonRotate(): ICSS {
        return { transform: `rotate(${(this.responsiveModel && this.responsiveModel.transform && -this.responsiveModel.transform.rotate) || 0}deg)` }
    }

    public deleteSelf(): void {
        this.atomModel.deleteSelf()
    }

    public copy(): void {
        this.atomModel.copySelf()
    }

    public get isLink(): boolean {
        return this.atomModel.linkModel.linkType !== 'None'
    }

    // ================= observer ===================
    // Modalに関わる全てのロジックがここに集約されている。
    public isSelected = false
    public select(): void {
        // まずboxを削除してから
        this.pageModel.boxModel = null
        this.pageModel.atomModel = null
        this.pageModel.atomModel = this.atomModel
        // guide
        this.pageModel.similarHeightAtomUUID = null
        this.pageModel.similarLeftAtomUUID = null
        this.pageModel.similarTopAtomUUID = null
        this.pageModel.similarWidthAtomUUID = null
        //
        this.pageModel.removeSelectedAtomModels()
        this.isSelected = true
    }
    public unselect(): void {
        this.isSelected = false
    }
    public selectedObserver(): void {
        const model = this.pageModel.atomModel
        if (model) {
            this.isSelected = this.atomModel.uuid === model.uuid
        } else {
            this.isSelected = false
        }
    }
    public created(): void {
        this.select()
        this.setCrossKeySelect()
        this.pageModel.setAtomObserver(this.selectedObserver)
    }
    public beforeDestroy(): void {
        this.removeCrossKeySelect()
        this.pageModel.removeAtomObserver(this.selectedObserver)
    }

    // ================= guide ===================
    public get isSimilarWidth(): boolean {
        return !this.isSelected && this.pageModel.similarWidthAtomUUID === this.atomModel.uuid
    }
    public get isSimilarHeight(): boolean {
        return !this.isSelected && this.pageModel.similarHeightAtomUUID === this.atomModel.uuid
    }
    public get isSimilarLeft(): boolean {
        return !this.isSelected && this.pageModel.similarLeftAtomUUID === this.atomModel.uuid
    }
    public get isSimilarTop(): boolean {
        return !this.isSelected && this.pageModel.similarTopAtomUUID === this.atomModel.uuid
    }
    public get isSimilarRight(): boolean {
        return !this.isSelected && this.pageModel.similarRightAtomUUID === this.atomModel.uuid
    }

    // ================= HTML ===================
    public get showEditor(): boolean {
        if (this.atomModel.atomType === 'Html') {
            return (this.atomModel as AtomHtmlVueModel).isShowEditor
        }
        return false
    }
    public set showEditor(input: boolean) {
        if (this.atomModel.atomType === 'Html') {
            const atomModel = this.atomModel as AtomHtmlVueModel
            atomModel.isShowEditor = input
        }
    }

    // ================= shift select ===================
    private multipleSelectedLocal = false
    public shiftSelectMouseEnter(event: MouseEvent): void {
        if (event.shiftKey || event.buttons) {
            this.shiftSelect()
        }
    }
    public shiftSelect(): void {
        this.pageModel.addSelectedAtomModels({ uuid: this.atomModel.uuid, drag: this.getDragFunc() })
        this.multipleSelectedLocal = true
    }
    public get multipleSelected(): boolean {
        const atom = this.pageModel.selectedAtomModels.find((item) => item.uuid === this.atomModel.uuid)
        return this.multipleSelectedLocal && !!atom
    }

    // ================= cross key select ===================
    public setCrossKeySelect(): void {
        addEventListener('keydown', this.keyDownFunc)
        addEventListener('keyup', this.keyUpFunc)
    }
    public removeCrossKeySelect(): void {
        removeEventListener('keydown', this.keyDownFunc)
        removeEventListener('keyup', this.keyUpFunc)
    }
    private keyDownFunc(e: KeyboardEvent) {
        if (e.target && (e.target as Element).tagName === 'INPUT') {
            // inputタグの場合は上下に動かさない
            this.pageModel.setPosition = null
            return
        } else if (this.showEditor && this.pageModel.setPosition) {
            // Writing
            this.pageModel.setPosition = null
        } else if (
            this.isSelected && //
            !this.multipleSelected &&
            !this.pageModel.setPosition &&
            !this.showEditor &&
            this.mode === this.pageModel.mode
        ) {
            this.pageModel.setPosition = this.getDragFuncCross()
            this.pageModel.setPosition(crossKeyHandler.getDiff(e))
        }

        if (
            this.isSelected && //
            this.pageModel.setPosition &&
            !this.showEditor &&
            this.mode === this.pageModel.mode &&
            ['ArrowUp', 'ArrowDown'].includes(e.key)
        ) {
            e.preventDefault() // スクロールを制御
        }
    }
    private keyUpFunc() {
        this.pageModel.setPosition = null
    }
}
