
import { AtomImageModel, ChillnnContentsBoxModel, ChillnnImage, ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
// component
import Modal from '../index.vue'
import QuestionContents from '../../../functionModule/Organisms/QuestionContents/index.vue'
import QuestionId from '../../../functionModule/Organisms/QuestionID/index.vue'
import QuestionLayer from '../../../functionModule/Organisms/QuestionLayer/index.vue'
import ImageResponsive from '../../../functionModule/Organisms/QuestionImageResponsive/index.vue'
import ImageTypeSelect from '../../../functionModule/Organisms/QuestionImageType/index.vue'
@Component({
    components: {
        Modal,
        QuestionContents,
        QuestionId,
        QuestionLayer,
        ImageResponsive,
        ImageTypeSelect,
    },
})
export default class ImageModal extends Vue {
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public get boxModel(): ChillnnContentsBoxModel {
        return this.atomModel.parentBoxModel as ChillnnContentsBoxModel
    }
    public get atomModel(): AtomImageModel {
        return this.pageModel.atomModel as AtomImageModel
    }
    public imageSetter(src: ChillnnImage): void {
        this.atomModel.src = src
    }
    public get responsiveStyleModel(): ResponsiveStyleModel {
        if (this.mode === 'pc') {
            return this.pageModel.atomModel!.pcStyleModel
        } else if (this.mode === 'mobile') {
            return this.pageModel.atomModel!.mobileStyleModel
        }
        throw new Error()
    }
}
