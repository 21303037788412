
import FunctionItem from './functionItem.vue'
import Vue from 'vue'
export default Vue.extend({
    components: {
        FunctionItem,
    },
    props: {
        func1: {
            type: String,
            required: true,
        },
        func2: {
            type: String,
            default: null,
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        addTopLine: {
            type: Boolean,
            required: false,
            default: false,
        },
        showFunctions: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        click(input: string): void {
            this.$emit('click', input)
        },
    },
})
