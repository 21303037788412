
import { Component, Prop } from 'vue-property-decorator'
// component
import SizeHandlerButton from '../../modules/sizeHandler/modules/sizeHandlerButton.vue'
import { SizeHandlerBase } from '../../modules/sizeHandler/base'
import { EditorMode, position } from '@/lib-components/editor/models/pageModel'
import { CHillnnStyleTransformModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/styles/modules/responsive/transform'
import { ChillnnStyleSpaceModel, Coordinate } from '@CHILLNN-Inc/chillnn-abr-studio'
import { PC_BASE_WIDTH } from '@CHILLNN-Inc/chillnn-abr-studio/dist/index'

@Component({
    components: {
        SizeHandlerButton,
    },
})
export default class LineSizeHandler extends SizeHandlerBase {
    @Prop({ required: true }) public spaceModel!: ChillnnStyleSpaceModel
    @Prop({ required: true }) public transformModel!: CHillnnStyleTransformModel
    @Prop({ required: true }) public coordinate1!: Coordinate
    @Prop({ required: true }) public coordinate2!: Coordinate
    @Prop({ required: true }) mode!: EditorMode

    public setRight(): void {
        // 角度とwidthから現状のX軸方向の長さを出す
        const func = (diff: position) => {
            // 今回のdiff分を足す。
            this.coordinate2.X = this.coordinate2.X + diff.x
            this.coordinate2.Y = this.coordinate2.Y + diff.y

            // // 長さをセット
            const width = Math.hypot(this.coordinate2.X - this.coordinate1.X, this.coordinate2.Y - this.coordinate1.Y)
            if (width !== this.sizeModel.width) {
                this.sizeModel.width = (this.sizeModel.width * 1050.0) / PC_BASE_WIDTH
            }
            this.sizeModel.width = width

            const cos = (this.coordinate2.X - this.coordinate1.X) / width
            if (this.coordinate1.Y > this.coordinate2.Y) this.transformModel.rotate = -Math.acos(cos) / (Math.PI / 180)
            else this.transformModel.rotate = Math.acos(cos) / (Math.PI / 180)
        }
        this.pageModel.setSetPosition(func)
    }

    public setLeft(): void {
        // 角度とwidthから現状のX軸方向の長さを出す
        const func = (diff: position) => {
            this.spaceModel.left = this.spaceModel.left + diff.x
            this.spaceModel.top = this.spaceModel.top + diff.y
            // 今回のdiff分を足す。
            this.coordinate1.X = this.coordinate1.X + diff.x
            this.coordinate1.Y = this.coordinate1.Y + diff.y

            // // 長さをセット
            const width = Math.hypot(this.coordinate2.X - this.coordinate1.X, this.coordinate2.Y - this.coordinate1.Y)
            if (width !== this.sizeModel.width) {
                this.sizeModel.width = (this.sizeModel.width * 1050.0) / PC_BASE_WIDTH
            }
            this.sizeModel.width = width

            const cos = (this.coordinate2.X - this.coordinate1.X) / width
            if (this.coordinate1.Y > this.coordinate2.Y) this.transformModel.rotate = -Math.acos(cos) / (Math.PI / 180)
            else this.transformModel.rotate = Math.acos(cos) / (Math.PI / 180)
        }
        this.pageModel.setSetPosition(func)
    }
}
