
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { RulerModelIterator } from '../models/RulerModelIterator'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { RulerModel } from '../models/RulerModel'
// component
import Ruler from './modules/Ruler.vue'

@Component({
    components: {
        Ruler,
    },
})
export default class RulerHandler extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel
    public rulerModelIterator: RulerModelIterator = new RulerModelIterator(this.pageModel)

    public created(): void {
        // ひとつづつ作る
        this.createRuler()
        this.createRuler()
        this.createRuler()
        this.createRuler()
        this.createRuler()
        this.createRuler()
    }
    public createRuler(): void {
        this.rulerModelIterator.createVerticalModel()
        this.rulerModelIterator.createHorizontalModel()
    }

    public get verticals(): RulerModel[] {
        return this.rulerModelIterator.verticalModels
    }
    public get horizontals(): RulerModel[] {
        return this.rulerModelIterator.horizontalModels
    }
}
