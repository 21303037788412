
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import vClickOutside from 'v-click-outside'

@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
})
export default class CommonModal extends Vue {
    @Prop({ required: true }) title!: string
}
