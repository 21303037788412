
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsAtomInterface, ChillnnContentsBoxModel, ICSS, ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentsPageVueModel, EditorMode } from '@/lib-components/editor/models/pageModel'
// Parts
import SizeHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/boxHandler.vue'
import AtomHtml from '../ChillnnContentsAtom/modules/AtomHtml/index.vue'
import AtomImage from '../ChillnnContentsAtom/modules/AtomImage/index.vue'
import AtomLine from '../ChillnnContentsAtom/modules/AtomLine/index.vue'
// iframe
import AtomMap from '../ChillnnContentsAtom/modules/iframe/AtomMap/index.vue'
import AtomYoutube from '../ChillnnContentsAtom/modules/iframe/AtomYoutube/index.vue'
import CopyButton from '../HandleButtons/CopyButton.vue'
import DeleteButton from '../HandleButtons/DeleteButton.vue'
@Component({
    components: {
        SizeHandler,
        // Parts
        AtomHtml,
        AtomImage,
        AtomLine,
        AtomMap,
        AtomYoutube,
        // copy
        CopyButton,
        DeleteButton,
    },
})
export default class ChillnnContentsBoxComponent extends Vue {
    @Prop({ required: true }) mode!: EditorMode
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) box!: ChillnnContentsBoxModel
    public isSelected = false
    public get uuid(): string {
        return this.box.uuid
    }
    public get atoms(): ChillnnContentsAtomInterface[] {
        return this.box.atoms
    }
    public get responsiveModel(): ResponsiveStyleModel | null {
        if (this.mode === 'pc') {
            return this.box.pcStyleModel
        } else if (this.mode === 'mobile') {
            return this.box.mobileStyleModel
        }
        return null
    }

    public get style(): ICSS {
        if (this.mode === 'pc') {
            return this.box.pcStyle
        } else {
            return this.box.mobileStyle
        }
    }
    public get customStyle(): ICSS {
        return {
            ...this.style,
            padding: `0px`,
        }
    }

    public mounted(): void {
        const el = document.getElementById(this.uuid)

        el &&
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
    }

    public select(): void {
        this.pageModel.atomModel = null
        this.pageModel.removeSelectedAtomModels()
        this.pageModel.boxModel = this.box
    }

    // observer
    public selectedObserver(): void {
        if (this.pageModel.atomModel) {
            this.isSelected = false
            return
        }
        const model = this.pageModel.boxModel
        if (model) {
            this.isSelected = this.box === model
        } else {
            this.isSelected = false
        }
    }

    public created(): void {
        this.pageModel.setBoxObserver(this.selectedObserver)
        this.select()
    }
    public beforeDestroy(): void {
        this.pageModel.removeBoxObserver(this.selectedObserver)
    }
}
