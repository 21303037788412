
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
@Component({})
export default class SizeHandlerButton extends Vue {
    @Prop({ required: true }) direction!: 'vertical' | 'horizontal' | 'diagonal_nesw' | 'diagonal_nwse'
    public mousedown(): void {
        this.$emit('select')
    }
}
