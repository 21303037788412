
import { ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class ColorListItem extends Vue {
    @Prop({ required: true }) public color!: string
    @Prop() public selectedColor!: string
    public select(): void {
        this.$emit('select', this.color)
    }

    public get style(): ICSS {
        return {
            backgroundColor: this.color,
        }
    }
    public get activeClass(): boolean {
        return this.color === this.selectedColor
    }
}
