
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import BoxModal from './modules/boxModal.vue'
import HtmlModal from './modules/atoms/htmlModal.vue'
import ImageModal from './modules/atoms/imageModal.vue'
import LineModal from './modules/atoms/lineModal.vue'
import MapModal from './modules/atoms/mapModal.vue'
import YoutubeModal from './modules/atoms/youtubeModal.vue'
import type { ChillnnContentAtomType } from '@CHILLNN-Inc/chillnn-abr-studio'

@Component({
    components: {
        BoxModal,
        HtmlModal,
        ImageModal,
        LineModal,
        MapModal,
        YoutubeModal,
    },
})
export default class ModalRouter extends Vue {
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel

    public get atomType(): ChillnnContentAtomType | null {
        if (!this.pageModel.atomModel) {
            return null
        }
        return this.pageModel.atomModel.atomType
    }
}
