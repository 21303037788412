var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map_container",class:{
        multipleSelected: _vm.multipleSelected,
        isSimilarWidth: _vm.isSimilarWidth,
        isSimilarHeight: _vm.isSimilarHeight,
        isSimilarTop: _vm.isSimilarTop,
        isSimilarLeft: _vm.isSimilarLeft,
        isSimilarRight: _vm.isSimilarRight,
    },style:(_vm.style),on:{"click":[function($event){$event.stopPropagation();if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.select.apply(null, arguments)},function($event){if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.shiftSelect.apply(null, arguments)}],"mousedown":_vm.dragAndMargin,"mouseup":_vm.removeDragHandler,"mouseenter":_vm.shiftSelectMouseEnter}},[_c('map-handler',{attrs:{"isSelected":_vm.isSelected,"sizeModel":_vm.responsiveModel.size,"spaceModel":_vm.responsiveModel.space,"pageModel":_vm.pageModel}},[_c('iframe',{staticClass:"map",attrs:{"src":_vm.googleMapLink,"frameborder":"0","loading":"lazy","allowfullscreen":"","width":_vm.width,"height":_vm.height}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected),expression:"isSelected"}],staticClass:"handler_buttons right",style:({ ..._vm.buttonRotate })},[_c('copy-button',{on:{"copy":_vm.copy}}),_c('delete-button',{on:{"click":_vm.deleteSelf}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }