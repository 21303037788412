
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import ListItem from './modules/ListItem.vue'
import draggable from 'vuedraggable'
import type { ChillnnContentsBox } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsPage/index'

@Component({
    components: {
        ListItem,
        draggable,
    },
})
export default class SlideOrder extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public get boxes(): ChillnnContentsBox[] {
        return this.pageModel.boxes
    }
    public staticPageModel: ChillnnContentsPageModel = new ChillnnContentsPageModel({
        name: { default: 'static', en: null },
        uuid: 'sample',
        boxes: [],
        commonStyle: {} as any,
    })
}
