import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel';
import { RulerModel } from './RulerModel';

export class RulerModelIterator {
    public verticalModels: RulerModel[] = []; // 縦を揃えるやつ
    public horizontalModels: RulerModel[] = []; // 横を揃えるやつ

    constructor(private pageModel: ChillnnContentsPageVueModel) {}

    public createVerticalModel(model?: RulerModel): void {
        if (model) {
            this.verticalModels.push(model);
        } else {
            this.verticalModels.push(new RulerModel('Vertical', this.pageModel, this.createVerticalModel, this.removeFunc));
        }
    }

    public createHorizontalModel(model?: RulerModel): void {
        if (model) {
            this.horizontalModels.push(model);
        } else {
            this.horizontalModels.push(new RulerModel('Horizontal', this.pageModel, this.createHorizontalModel, this.removeFunc));
        }
    }

    private removeFunc(self: RulerModel) {
        this.verticalModels = this.verticalModels.filter((model) => model !== self);
        this.horizontalModels = this.horizontalModels.filter((model) => model !== self);
    }
}
