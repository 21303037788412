
import type { ChillnnContentsBox, ChillnnContentsPage, ICSS, StudioClickEventHandler } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsPage/index'
import { PC_BASE_WIDTH, SP_BASE_WIDTH } from '@CHILLNN-Inc/chillnn-abr-studio/dist/index'
import { LangCode } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
// component
import { StyledDiv } from '../common/StyledComponentFactory'
import BoxRenderer from './ChillnnBoxRenderer.vue'

@Component({
    components: {
        StyledDiv,
        BoxRenderer,
    },
})
export default class PageRenderer extends Vue {
    @Prop({ required: true }) page!: ChillnnContentsPage // ページのデータ
    @Prop({ required: true }) clickEventHandler!: StudioClickEventHandler // イベントハンドラー

    // モデル
    public pageModel = new ChillnnContentsPageModel(this.page, {
        scale: this.getScale(),
        clickEventHandler: this.clickEventHandler,
    })

    @Watch('page')
    public setNewPageModel(): void {
        this.pageModel = new ChillnnContentsPageModel(this.page, {
            scale: this.getScale(),
            clickEventHandler: this.clickEventHandler,
        })
    }

    public get boxes(): ChillnnContentsBox[] {
        return this.pageModel.boxes
    }
    public get pcStyle(): ICSS {
        return this.pageModel.pcStyle
    }
    public get mobileStyle(): ICSS {
        return this.pageModel.mobileStyle
    }

    // =============================================================
    // 横幅
    // =============================================================
    @Prop({ required: true }) width!: number // 画面サイズ
    @Watch('width')
    public onResize(): void {
        this.pageModel.scaleCoordinator.scale = this.getScale()
    }
    public getScale(): number {
        if (this.width && this.width > 900) {
            return this.width / PC_BASE_WIDTH
        } else if (this.width && this.width < 900) {
            return this.width / SP_BASE_WIDTH
        } else {
            return getScale()
        }
    }

    // =============================================================
    // 言語設定
    // =============================================================
    @Prop({ default: LangCode.default }) lang!: LangCode // 言語設定
    @Watch('lang')
    public langSet(): void {
        this.pageModel.languageCoordinator.lang = this.lang
    }

    // =============================================================
    // Life Cycle
    // =============================================================
    public created(): void {
        // 横幅の設定
        window.onresize = this.onResize
        window.addEventListener('orientationchange resize', this.onResize)

        // 言語の設定
        this.langSet()
    }
}

/**
 * 基準となるscale
 */
function getScale(): number {
    const width = (window && window.innerWidth) || 1440 // 画面の横幅
    if (width > 900) {
        return width / PC_BASE_WIDTH
    } else {
        return width / SP_BASE_WIDTH
    }
}
