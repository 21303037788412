
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import Modal from '../../modal.vue'
import ImageInput from '@/lib-components/editor/components/menu/rightmenu/functionModule/Molecule/ImagePicker/input.vue'
import ImageListItem from '@/lib-components/editor/components/menu/rightmenu/functionModule/Molecule/ImagePicker/ListItem.vue'
import type { AtomImageModel, ChillnnImage, Scalars, StudioImageMast } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentAtomType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { imageRepository } from '../../../rightmenu/functionModule/Molecule/ImagePicker/imageRepositorySample'
@Component({
    components: {
        Modal,
        ImageInput,
        ImageListItem,
    },
})
export default class ImageModal extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel
    public imageRepository: StudioImageMast[] = imageRepository()
    public imageUploader = this.pageModel.imageUploader
    public imageDeleter = this.pageModel.imageDeleter

    public get isShowModal(): boolean {
        if (this.pageModel.isShowImageModal) {
            this.fetchImages()
        }
        return this.pageModel.isShowImageModal
    }

    public close(): void {
        this.pageModel.isShowImageModal = false
    }

    public async fetchImages(): Promise<void> {
        if (this.pageModel.imageRepository) {
            this.imageRepository = await this.pageModel.imageRepository()
        }
    }

    public imageSelect(src: ChillnnImage): void {
        if (this.pageModel.atomModel && this.pageModel.atomModel.atomType === ChillnnContentAtomType.Image) {
            const imageModel = this.pageModel.atomModel as AtomImageModel
            imageModel.src = src
        }
    }

    public async deleteImage(uuid: Scalars['ID']): Promise<void> {
        if (!this.imageDeleter) {
            // 開発環境
        } else {
            await this.imageDeleter(uuid)
            // ここで表示するImageの更新
            await this.fetchImages()
        }
    }

    public async getImage(base64: string): Promise<void> {
        if (!this.imageUploader) {
            // 開発環境
            this.imageRepository.unshift({
                ownerID: '',
                uuid: '',
                src: base64,
            })
        } else {
            // ここで表示するImageの更新
            await this.fetchImages()
        }
    }

    public async created(): Promise<void> {
        // loadする
        await this.fetchImages()
    }
}
