
import type { ChillnnContentsPage, ChillnnContentsPageModel, IChillnnTemplatePageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ContentsDuplicate } from '@CHILLNN-Inc/chillnn-abr-studio/dist/service/duplicate'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// components
import ChillnnPageRenderer from '@/lib-components/renderer/components/ChillnnPageRenderer.vue'
import BoxRendererCard from './modules/snackTemplateBox.vue'
import PageRendererCard from './modules/snackTemplatePage.vue'

@Component({
    components: {
        ChillnnPageRenderer,
        BoxRendererCard,
        PageRendererCard,
    },
})
export default class SnackTemplateContents extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) templatePageModel!: IChillnnTemplatePageModel
    @Prop({ required: true }) closeModal!: () => void
    public contentsPage: ChillnnContentsPage | null = null
    public selectedDevice: 'PC' | 'MOBILE' = 'MOBILE'

    public async created(): Promise<void> {
        this.contentsPage = await this.templatePageModel.fetchContent()
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public eventHandler(): void {}
    public async adaptTemplate(): Promise<void> {
        const boxes = this.pageModel.boxes
        let uuid = undefined
        if (boxes.length) {
            uuid = boxes[boxes.length - 1].uuid
        }
        const contentsPage = await this.templatePageModel.fetchContent()
        for (let i = 0; i < contentsPage.boxes.length; i++) {
            const box = ContentsDuplicate.duplicateBox(contentsPage.boxes[i])
            this.pageModel.pushBox(box, uuid)
        }
        this.closeModal()
    }
    public changeDevice(device: 'PC' | 'MOBILE'): void {
        this.selectedDevice = device
    }
}
