
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { CHillnnStyleTransformModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/styles/modules/responsive/transform'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import { ResponsiveStyleModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import AppInput from '../../Atom/textInput.vue'
import TextAlign from '../../Atom/textAlign.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import DropShadow from '../../Molecule/DropShadowInput.vue'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        AppInput,
        TextAlign,
        ColorPicker,
        DropShadow,
    },
})
export default class QuestionFont extends Vue {
    @Prop({ required: true }) responsiveModel!: ResponsiveStyleModel
    @Prop({ required: true }) mode!: 'pc' | 'mobile'

    public get fontWeight(): SelectItem[] {
        return [
            {
                key: 'ミディアム',
                value: 400,
            },
            {
                key: 'ボールド',
                value: 900,
            },
        ]
    }

    public get statement(): string {
        if (this.mode === 'pc') {
            return 'PCのフォント設定'
        } else if (this.mode === 'mobile') {
            return 'モバイルのフォント設定'
        }
        return ''
    }

    public get borderRadius(): number {
        return this.responsiveModel.border!.borderTopLeftRadius
    }
    public set borderRadius(input: number) {
        const border = this.responsiveModel.border!
        border.borderTopLeftRadius = input
        border.borderTopRightRadius = input
        border.borderBottomRightRadius = input
        border.borderBottomLeftRadius = input
    }
    public get borderSolid(): number {
        return this.responsiveModel.border!.borderSolid
    }
    public set borderSolid(input: number) {
        this.responsiveModel.border!.borderSolid = input
    }
    public get borderColor(): string {
        return this.responsiveModel.border!.color
    }
    public set borderColor(input: string) {
        this.responsiveModel.border!.color = input
    }
    public get borderOpacity(): number {
        return this.responsiveModel.border!.opacity
    }
    public set borderOpacity(input: number) {
        this.responsiveModel.border!.opacity = input
    }
    public get transform(): CHillnnStyleTransformModel | null {
        return this.responsiveModel.transform
    }
}
