import { ChillnnContentsPageVueModel, position } from '@/lib-components/editor/models/pageModel'
import { ChillnnStyleSizeModel, ChillnnStyleSpaceModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PaddingHandlerBase extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) isSelected!: boolean
    @Prop({ required: true }) sizeModel!: ChillnnStyleSizeModel
    @Prop({ required: true }) spaceModel!: ChillnnStyleSpaceModel

    public get style(): { [key: string]: string } {
        if (this.isSelected) {
            return {
                border: '0 solid rgba(32,209,0,.2)',
                borderWidth: this.spaceModel.style.padding,
            }
        } else {
            return {
                padding: this.spaceModel.style.padding,
            }
        }
    }
    public get wrapperClass(): string {
        return this.isSelected ? 'selected' : ''
    }

    // ========================= handler func ============================
    public setTop(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingTop = this.spaceModel.paddingTop + diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setRight(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingRight = this.spaceModel.paddingRight - diff.x
        }
        this.pageModel.setSetPosition(func)
    }
    public setBottom(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingBottom = this.spaceModel.paddingBottom - diff.y
        }
        this.pageModel.setSetPosition(func)
    }
    public setLeft(): void {
        const func = (diff: position) => {
            this.spaceModel.paddingLeft = this.spaceModel.paddingLeft + diff.x
        }
        this.pageModel.setSetPosition(func)
    }
    // ========================= handler style ============================
    public get topStyle(): { top: string } {
        return {
            top: `${this.spaceModel.paddingTop}px`,
        }
    }
    public get rightStyle(): { right: string } {
        return {
            right: `${this.spaceModel.paddingRight}px`,
        }
    }
    public get bottomStyle(): { bottom: string } {
        return {
            bottom: `${this.spaceModel.paddingBottom}px`,
        }
    }
    public get leftStyle(): { left: string } {
        return {
            left: `${this.spaceModel.paddingLeft}px`,
        }
    }
}
