
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class DeleteButton extends Vue {
    public clicked(): void {
        this.$emit('click')
    }
}
