
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { LinkModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class LinkButton extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public linkModel!: LinkModel

    public link(): void {
        this.pageModel.linkModel = this.linkModel
    }
}
