
import type { ChillnnContentsBoxModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import ImagePicker from '../../Molecule/ImagePicker/index.vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { BoxType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        ColorPicker,
        ImagePicker,
    },
})
export default class QuestionBackground extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public boxModel!: ChillnnContentsBoxModel
    public readonly kv: SelectItem[] = [
        {
            key: '設定しない',
            value: null,
        },
        {
            key: 'ヘッダーにする',
            value: BoxType.Header,
        },
    ]
}
