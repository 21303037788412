
import { ChillnnStyleDropShadowResponsiveModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import AppInput from '../Atom/textInput.vue'
import ColorPicker from './ColorPicker/index.vue'

@Component({
    components: {
        AppInput,
        ColorPicker,
    },
})
export default class DropShadowInput extends Vue {
    @Prop({ required: true }) public dropShadowModel!: ChillnnStyleDropShadowResponsiveModel

    public get dropShadowColor(): string {
        return this.dropShadowModel.color
    }
    public set dropShadowColor(input: string) {
        this.dropShadowModel.color = input
    }
    public get dropShadowOpacity(): number {
        return this.dropShadowModel.opacity
    }
    public set dropShadowOpacity(input: number) {
        this.dropShadowModel.opacity = input
    }
}
