
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsPageVueModel } from '../../models/pageModel'
import RulerHorizontal from './RulerHandler/components/RulerHorizontal.vue'
import RulerVertical from './RulerHandler/components/RulerVertical.vue'
// mixins
import { CrossKeyEventMixin, MouseEventMixin } from './Mixins/_index'

@Component({
    components: {
        RulerHorizontal,
        RulerVertical,
    },
    mixins: [
        MouseEventMixin, //
        CrossKeyEventMixin,
    ],
})
export default class SpaceHandlerWrapper extends Vue {
    public get isShowRuler(): boolean {
        return !!this.pageModel.atomModel || !!this.pageModel.boxModel || !!this.pageModel.selectedAtomModels.length
    }
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel

    public clear(): void {
        this.pageModel.boxModel = null
        this.pageModel.atomModel = null
    }
}
