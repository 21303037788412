import { ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsPage'
import { Observer } from '@CHILLNN-Inc/chillnn-abr-studio/dist/util/modules/observer'

import type {
    ChillnnContentsPage,
    ChillnnImage,
    IChillnnTemplateCategoryModel,
    IChillnnTemplateModelRepository,
    ImageMast,
    LinkModel,
    OtherResource,
    Scalars,
    StudioClickEventType,
    StudioImageMast,
} from '@CHILLNN-Inc/chillnn-abr-studio'
export type position = {
    x: number
    y: number
}
export type EditorMode = 'pc' | 'mobile'
export type SelectedAtomModel = { uuid: Scalars['ID']; drag: (diff: position) => void }

export class ChillnnContentsPageVueModel extends ChillnnContentsPageModel {
    public imageRepository: (() => Promise<StudioImageMast[]>) | null
    public imageUploader: ((image: File) => Promise<ChillnnImage>) | null
    public imageDeleter: ((uuid: Scalars['ID']) => Promise<ImageMast>) | null
    public availableClickEvents: StudioClickEventType[]
    public isShowMultiLang: boolean
    // resources
    public pageResources: OtherResource[]
    public hotelPlanResources: OtherResource[]
    public hotelRoomResources: OtherResource[]
    public templates: IChillnnTemplateCategoryModel
    public parentTemplates: IChillnnTemplateCategoryModel
    public snackTemplates!: IChillnnTemplateModelRepository

    // content
    public linkModel: LinkModel | null = null
    public isShowImageModal = false

    constructor(
        page: ChillnnContentsPage,
        public mode: EditorMode,
        options: {
            scale?: number
            imageRepository?: () => Promise<StudioImageMast[]>
            imageUploader?: (image: File) => Promise<ChillnnImage>
            imageDeleter?: (uuid: Scalars['ID']) => Promise<ImageMast>
            availableClickEvents: StudioClickEventType[]
            isShowMultiLang: boolean
            // 別ページ
            pageResources: OtherResource[]
            // プランページ
            hotelPlanResources: OtherResource[]
            hotelRoomResources: OtherResource[]
            // マイテンプレート
            templates: IChillnnTemplateCategoryModel
            snackTemplates: IChillnnTemplateModelRepository
            // 共通テンプレート
            parentTemplates: IChillnnTemplateCategoryModel
        }
    ) {
        super(page, options)

        this.imageRepository = options.imageRepository || null
        this.imageUploader = options.imageUploader || null
        this.imageDeleter = options.imageDeleter || null
        this.availableClickEvents = options.availableClickEvents
        this.isShowMultiLang = options.isShowMultiLang

        // resouces
        this.pageResources = options.pageResources
        this.hotelPlanResources = options.hotelPlanResources
        this.hotelRoomResources = options.hotelRoomResources

        // my template
        this.templates = options.templates
        this.snackTemplates = options.snackTemplates

        // parent template
        this.parentTemplates = options.parentTemplates
    }

    private atomModalObserver: Observer = new Observer()
    private boxModalObserver: Observer = new Observer()
    // ========================================
    //
    // atom
    //
    // ========================================
    public toggleAtomModal(): void {
        this.atomModalObserver.emit()
    }
    public addAtomToggleModalSwitch(f: () => void): void {
        this.atomModalObserver.add(f)
    }
    public removeAtomToggleModalSwitch(f: () => void): void {
        this.atomModalObserver.delete(f)
    }
    // ========================================
    //
    // box
    //
    // ========================================
    public toggleBoxModal(): void {
        this.boxModalObserver.emit()
    }
    public addBoxToggleModalSwitch(f: () => void): void {
        this.boxModalObserver.add(f)
    }
    public removeBoxToggleModalSwitch(f: () => void): void {
        this.boxModalObserver.delete(f)
    }
    // ========================================
    //
    // space handle
    //
    // ========================================
    public setPosition: null | ((diff: position) => void) = null // positionを規定するhandler
    public setSetPosition(func: (diff: position) => void): void {
        this.setPosition = func
    }
    // ========================================
    //
    // 複数選択
    //
    // ========================================
    public selectedAtomModels: SelectedAtomModel[] = []
    public addSelectedAtomModels(atom: SelectedAtomModel): void {
        this.atomModel = null
        const findedAtom = this.selectedAtomModels.find((item) => item.uuid === atom.uuid)
        if (!findedAtom) {
            this.selectedAtomModels.push(atom)
        }
    }
    public removeSelectedAtomModels(): void {
        this.selectedAtomModels = []
    }
    public setSetPositionSelectedAtomModels(): void {
        const funcs = [...this.selectedAtomModels].map((item) => item.drag)
        const func = (diff: position) => {
            funcs.map((func) => {
                func(diff)
            })
        }
        this.setPosition = func
    }
}
