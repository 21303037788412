
import { Component } from 'vue-property-decorator'
import { AtomRendererBase } from '../base'
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio'
// component
import { StyledDiv } from '../../../common/StyledComponentFactory'
@Component({
    components: {
        StyledDiv,
    },
})
export default class YoutubeRenderer extends AtomRendererBase {
    public get src(): AtomYoutubeModel {
        return this.atomModel as AtomYoutubeModel
    }

    public get youtubeLink(): string {
        return `https://www.youtube.com/embed/${this.src.videoID}?autoplay=${this.src.autoPlay ? '1' : '0'}&loop=1&modestbranding=1`
    }
}
