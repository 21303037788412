
import type { ChillnnContentsAtomInterface, ChillnnContentsBox, ChillnnContentsPageModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { StyledDiv } from '../common/StyledComponentFactory'
import { ChillnnContentsBoxModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsBox'
import { BoxType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'

// component
import HtmlRenderer from './atoms/HtmlRenderer.vue'
import ImageRenderer from './atoms/ImageRenderer.vue'
import LineRenderer from './atoms/LineRenderer.vue'
// iframe
import MapRenderer from './atoms/iframe/MapRenderer.vue'
import YoutubeRenderer from './atoms/iframe/YoutubeRenderer.vue'

@Component({
    components: {
        StyledDiv,
        HtmlRenderer,
        ImageRenderer,
        LineRenderer,
        MapRenderer,
        YoutubeRenderer,
    },
})
export default class BoxRenderer extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) box!: ChillnnContentsBox
    @Prop({ required: true }) width!: number
    public boxModel = new ChillnnContentsBoxModel(this.pageModel, this.box)
    public get atoms(): ChillnnContentsAtomInterface[] {
        return this.box.atoms
    }
    public get pcStyle(): ICSS {
        if (this.width <= 900) {
            return this.boxModel.mobileStyle
        } else {
            return this.boxModel.pcStyle
        }
    }
    public get mobileStyle(): ICSS {
        return this.boxModel.mobileStyle
    }
    // =====================================================
    public get headerStyle(): ICSS {
        if (this.isHeader) {
            return {
                position: 'fixed',
                top: 0,
                zIndex: 1,
                width: (this.width && `${this.width}px`) || '100%',
            }
        } else {
            return {}
        }
    }
    public get isHeader(): boolean {
        return this.boxModel.boxType === BoxType.Header
    }
}
