
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class FunctionItem extends Vue {
    @Prop({ required: true }) funcText!: string
    public isSelectedFunction = false

    public click(input: string): void {
        this.$emit('click', input)
    }
}
