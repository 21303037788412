
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
// tiptap
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Typography from '@tiptap/extension-typography'
import BubbleMenu from './BubbleMenu.vue'

@Component({
    components: {
        EditorContent,
        BubbleMenu,
    },
})
export default class ChillnnEditor extends Vue {
    @Prop({ required: true }) public value!: string
    // eslint-disable-next-line
    public editor: Editor | null = null

    // ここからv-model用のパーツ
    public get localVal(): string {
        return this.value
    }
    public set localVal(val: string) {
        this.$emit('input', val)
    }

    @Watch('value')
    public watch(): void {
        // 双方向バインディングを実現している。
        if (this.editor) {
            const val = this.editor.getHTML()
            if (val !== this.localVal) {
                this.editor.commands.setContent(this.localVal)
            }
        }
    }

    public mounted(): void {
        this.editor = new Editor({
            extensions: [StarterKit, Typography],
            content: this.localVal,
            onUpdate: () => {
                this.localVal = this.editor!.getHTML()
            },
            editable: true,
        })
    }
    public beforeDestroy(): void {
        if (this.editor) {
            this.editor.destroy()
        }
    }
}
