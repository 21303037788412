
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { Category } from '../../index.vue'
@Component({})
export default class CategoryButton extends Vue {
    @Prop({ required: true }) category!: Category
    @Prop({ required: true }) selectedCategory!: Category | null

    public selected(): void {
        this.$emit('selected', this.category)
    }

    public get isSelected(): boolean | null {
        return this.selectedCategory && this.category.key === this.selectedCategory.key
    }
}
