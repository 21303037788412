
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import AppInput from '../../Atom/textInput.vue'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        AppInput,
    },
})
export default class QuestionYoutube extends Vue {
    @Prop({ required: true }) atomModelYoutube!: AtomYoutubeModel
}
