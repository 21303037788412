
import { ChillnnImage, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class MultipleImageCardItem extends Vue {
    @Prop({ required: true }) image!: ChillnnImage
    public remove(): void {
        this.$emit('remove')
    }
    public get style(): ICSS {
        return {
            backgroundImage: `url(${this.image})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }
}
