import { VueConstructor } from 'vue/types/umd'

import { setDirectives } from './util/setDirectives'

// export const ChillnnStudioEditor = (): any => import('./editor/index.vue')
export { default as ChillnnStudioEditor } from './editor/index.vue'
// export const ChillnnStudioRenderer = (): any => import('./renderer/index.vue')
export { default as ChillnnStudioRenderer } from './renderer/index.vue'

export const initStudioComponent = (vue: VueConstructor<Vue>): void => {
    setDirectives(vue)
}
