
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { LinkModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { StudioClickEventType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import SelectBox, { SelectItem } from '../../../rightmenu/functionModule/Molecule/SelectBox/index.vue'
import Modal from '../../modal.vue'
import ResourceSelect from './ResourcePicker/index.vue'
import TextInput from '../../../rightmenu/functionModule/Atom/textInput.vue'

@Component({
    components: {
        Modal,
        SelectBox,
        ResourceSelect,
        TextInput,
    },
})
export default class LinkModal extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel

    public get isShow(): boolean {
        return !!this.pageModel.linkModel
    }

    public get linkModel(): LinkModel | null {
        return this.pageModel.linkModel
    }

    public close(): void {
        this.pageModel.linkModel = null
    }

    public get kv(): SelectItem[] {
        return [
            {
                key: 'なし',
                value: StudioClickEventType.None,
            },
            {
                key: '宿泊予約のボタンにする（プラン）',
                value: StudioClickEventType.HotelReservation,
            },
            {
                key: '宿泊予約のボタンにする（お部屋）',
                value: StudioClickEventType.HotelReservationRoom,
            },
            {
                key: '他のコンテンツに飛ばす',
                value: StudioClickEventType.StudioResource,
            },
            {
                key: '外部リンクに飛ばす',
                value: StudioClickEventType.ExternalLink,
            },
            {
                key: 'ページ内リンク',
                value: StudioClickEventType.AnchorLink,
            },
        ].filter((item) => {
            // 利用可能なイベントのみを利用する
            return (
                this.pageModel.availableClickEvents.includes(item.value) || //
                item.value === StudioClickEventType.None ||
                item.value === StudioClickEventType.AnchorLink
            )
        })
    }
}
