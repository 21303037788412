
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { ChillnnContentsBoxModel, ChillnnStyleBackgroundModel } from '@CHILLNN-Inc/chillnn-abr-studio'
// component
import Modal from './index.vue'
import QuestionContents from '../../functionModule/Organisms/QuestionContents/index.vue'
import QuestionBoxType from '../../functionModule/Organisms/QuestionBoxType/index.vue'
import QuestionBackground from '../../functionModule/Organisms/QuestionBackground/index.vue'
@Component({
    components: {
        Modal,
        QuestionContents,
        QuestionBoxType,
        QuestionBackground,
    },
})
export default class BoxModal extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    public get boxModel(): ChillnnContentsBoxModel {
        return this.pageModel.boxModel as ChillnnContentsBoxModel
    }
    public get background(): ChillnnStyleBackgroundModel {
        return this.boxModel.commonStyleModel.background as ChillnnStyleBackgroundModel
    }
}
