
import type { ChillnnContentsBox, ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import BoxRenderer from '@/lib-components/renderer/components/ChillnnBoxRenderer.vue'

@Component({
    components: {
        BoxRenderer,
    },
})
export default class ListItem extends Vue {
    @Prop({ required: true }) staticPageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) box!: ChillnnContentsBox

    public mounted(): void {
        // heightのリサイズ
        setTimeout(() => {
            const element = document.getElementById(this.box!.uuid)!
            setHeight(element)
        }, 0.5)
    }
}
function setHeight(element: HTMLElement) {
    const height = element.clientHeight
    element.style.marginBottom = `-${Math.round(height - height * 0.75 /* scaleの大きさと一緒 */)}px`
}
