
import Vue from 'vue'
export default Vue.extend({
    props: {
        tips: {
            type: String,
            required: true,
        },
        isSelected: {
            type: Boolean,
            required: false,
            default: false,
        },
        addTopLine: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTips: {
            type: Boolean,
            required: false,
            default: false,
        },
        emphasis: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        click(): void {
            this.$emit('click')
        },
    },
})
