
import { AtomLineModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component } from 'vue-property-decorator'
import { AtomBase } from '../../AtomBase'
// component
import LinkButton from '../../../HandleButtons/LinkButton/index.vue'
import DeleteButton from '../../../HandleButtons/DeleteButtonAtom.vue'
import CopyButton from '../../../HandleButtons/CopyButtonAtom.vue'
import LineHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/atom/lineHandler.vue'
// directives
import vClickOutside from 'v-click-outside'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        LinkButton,
        DeleteButton,
        CopyButton,
        LineHandler,
    },
})
export default class atomLINE extends AtomBase {
    public get wrapperStyle(): ICSS {
        return {
            ...this.style,
            filter: undefined,
            border: 'none',
            transformOrigin: '0 0',
        }
    }

    public get lineStyle(): ICSS {
        if (this.mode === 'pc') {
            return (this.atomModel as AtomLineModel).backgroundCssPc
        } else {
            return (this.atomModel as AtomLineModel).backgroundCssMobile
        }
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public get coordinate() {
        if (this.mode === 'pc') {
            return (this.atomModel as AtomLineModel).coordinatePc
        } else {
            return (this.atomModel as AtomLineModel).coordinateMobile
        }
    }
    public get boxLineStyle(): ICSS {
        return {
            ...this.style,
            transform: undefined,
            transformOrigin: '0 0',
            left: 0,
            top: 0,
            ...this.lineStyle,
        }
    }
}
