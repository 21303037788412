
import { position } from '@/lib-components/editor/models/pageModel'
import type {
    ChillnnAtomModel,
    ChillnnContentsAtomHtml,
    ChillnnContentsAtomImage,
    ChillnnContentsAtomInterface,
    ChillnnContentsAtomLine,
    ChillnnContentsAtomMap,
    ChillnnContentsAtomYoutube,
    ChillnnContentsBoxModel,
} from '@CHILLNN-Inc/chillnn-abr-studio'
import { AtomHtmlModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomHtml'
import { AtomImageModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomImage'
import { AtomLineModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/AtomLine'
import { AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomMap'
import { AtomYoutubeModel } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/domain/models/ChillnnContentsAtom/modules/iframe/AtomYoutube'

import { ChillnnContentAtomType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop } from 'vue-property-decorator'
import { SizeHandlerBase } from '../modules/sizeHandler/base'
// component
import SizeHandlerButton from '../modules/sizeHandler/modules/sizeHandlerButton.vue'

@Component({
    components: {
        SizeHandlerButton,
    },
})
export default class SizeHandlerBottomOnly extends SizeHandlerBase {
    @Prop({ required: true }) mode!: 'pc' | 'mobile'
    @Prop({ required: true }) boxModel!: ChillnnContentsBoxModel
    @Prop({ required: true }) atoms!: ChillnnContentsAtomInterface[]
    public setTop(): void {
        const atoms = this.atoms.map((item) => this.getAtomModel(item))
        const func = (diff: position) => {
            this.sizeModel.height = this.sizeModel.height - diff.y
            atoms.map((item) => {
                if (this.mode === 'pc') {
                    if (item.pcStyleModel.space) {
                        item.pcStyleModel.space.top = item.pcStyleModel.space.top - diff.y
                    }
                } else if (this.mode === 'mobile') {
                    if (item.mobileStyleModel.space) {
                        item.mobileStyleModel.space.top = item.mobileStyleModel.space.top - diff.y
                    }
                }
            })
        }
        this.pageModel.setSetPosition(func)
    }
    public setBottom(): void {
        const func = (diff: position) => {
            this.sizeModel.height = this.sizeModel.height + diff.y
        }
        this.pageModel.setSetPosition(func)
    }

    private getAtomModel(atom: ChillnnContentsAtomInterface): ChillnnAtomModel {
        switch (atom.contentAtomType) {
            case ChillnnContentAtomType.Html:
                return new AtomHtmlModel(this.pageModel, this.boxModel, atom as ChillnnContentsAtomHtml)
            case ChillnnContentAtomType.Image:
                return new AtomImageModel(this.pageModel, this.boxModel, atom as ChillnnContentsAtomImage)
            case ChillnnContentAtomType.Map:
                return new AtomMapModel(this.pageModel, this.boxModel, atom as ChillnnContentsAtomMap)
            case ChillnnContentAtomType.Youtube:
                return new AtomYoutubeModel(this.pageModel, this.boxModel, atom as ChillnnContentsAtomYoutube)
            case ChillnnContentAtomType.Line:
                return new AtomLineModel(this.pageModel, this.boxModel, atom as ChillnnContentsAtomLine)
            default:
                throw new Error()
        }
    }
}
