
import type { AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { MapType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component } from 'vue-property-decorator'
import { AtomBase } from '../../../AtomBase'
// component
import DeleteButton from '../../../../HandleButtons/DeleteButtonAtom.vue'
import CopyButton from '../../../../HandleButtons/CopyButtonAtom.vue'
import MapHandler from '@/lib-components/editor/components/handler/spaceHandler/resources/atom/iframeHandler.vue'
// directives
import vClickOutside from 'v-click-outside'
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        DeleteButton,
        CopyButton,
        MapHandler,
    },
})
export default class AtomMAP extends AtomBase {
    public model = this.getModel() as AtomMapModel
    public get googleMapLink(): string {
        switch (this.model.mapType) {
            case MapType.ADDRESS:
                return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAdBEmdyqDVBOJVirt5FwS7ztn_gFmFIKU&q=${encodeURIComponent(`${this.model.address}`)}&zoom=${
                    (this.atomModel as AtomMapModel).scale
                }`
            case MapType.GEOLOCATION:
                return `https://www.google.com/maps/embed/v1/place?key=AIzaSyAdBEmdyqDVBOJVirt5FwS7ztn_gFmFIKU&q=${this.model.geoLocation.lat},${this.model.geoLocation.lon}&zoom=${
                    (this.atomModel as AtomMapModel).scale
                }`
            default:
                throw new Error()
        }
    }

    public get width(): string {
        return `${this.responsiveModel!.size!.width}px`
    }
    public get height(): string {
        return `${this.responsiveModel!.size!.height}px`
    }
}
