
import type { ChillnnImage, ChillnnStyleBackgroundModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ChillnnContentsPageVueModel } from '@/lib-components/editor/models/pageModel'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { BackgroundType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import ColorPicker from '../../Molecule/ColorPicker/index.vue'
import ImagePicker from '../../Molecule/ImagePicker/index.vue'
import RangePicker from '../../Atom/rangePicker.vue'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        ColorPicker,
        ImagePicker,
        RangePicker,
    },
})
export default class QuestionBackground extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    @Prop({ required: true }) public backgroundModel!: ChillnnStyleBackgroundModel
    public readonly kv: SelectItem[] = [
        {
            key: '透明にする',
            value: BackgroundType.TRANSPARENT,
        },
        {
            key: '背景色を指定する',
            value: BackgroundType.COLOR,
        },
        {
            key: '背景画像を指定する',
            value: BackgroundType.IMG,
        },
    ]
    public imageSetter(src: ChillnnImage): void {
        this.backgroundModel.image = src
    }
}
