
import { LangCode } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import MenuButton from './common/menuButton.vue'
import MenuButtonMultiFunction from './common/menuButtonMultiFunction/menuButtonMultiFunction.vue'
import BoxRepository from './boxRepository/index.vue'
import SlideOrder from './slideOrder/index.vue'
// directives
import vClickOutside from 'v-click-outside'
import { ChillnnContentsPageVueModel, EditorMode } from '@/lib-components/editor/models/pageModel'

/**
 * サイドメニュー（左側）のテキスト
 * 「保存して終了」
 */
export const LEFT_SIDE_MENU_TEXT_SAVE_AND_EXIT = '保存して終了' as const
/**
 * サイドメニュー（左側）のテキスト
 * 「一時保存」
 */
export const LEFT_SIDE_MENU_TEXT_SAVE = '一時保存' as const

@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        MenuButton,
        MenuButtonMultiFunction,
        BoxRepository,
        SlideOrder,
    },
})
export default class SidemenuLeft extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageVueModel

    public LEFT_SIDE_MENU_TEXT_SAVE_AND_EXIT = LEFT_SIDE_MENU_TEXT_SAVE_AND_EXIT
    public LEFT_SIDE_MENU_TEXT_SAVE = LEFT_SIDE_MENU_TEXT_SAVE

    public isShowBoxRepository = false
    public isShowSlideOrder = false
    public close(): void {
        this.isShowBoxRepository = false
        this.isShowSlideOrder = false
    }
    public toggleBoxRepository(): void {
        const bool = this.isShowBoxRepository
        this.close()
        this.isShowBoxRepository = !bool
    }
    public toggleSlideOrder(): void {
        const bool = this.isShowSlideOrder
        this.close()
        this.isShowSlideOrder = !bool
    }

    // save
    public save(input: string): void {
        this.$emit('save', input)
    }
    // preview
    public preview(): void {
        this.$emit('preview')
    }

    // responsive
    @Prop({ required: true }) mode!: EditorMode
    @Prop({ required: true }) modeChanger!: (mode: EditorMode) => void
    public toPC(): void {
        this.modeChanger('pc')
        this.pageModel.operationCoordinator.addOperation({
            operation: () => this.modeChanger('pc'),
            inverseOperation: () => this.modeChanger('mobile'),
        })
    }
    public toMobile(): void {
        this.modeChanger('mobile')
        this.pageModel.operationCoordinator.addOperation({
            operation: () => this.modeChanger('mobile'),
            inverseOperation: () => this.modeChanger('pc'),
        })
    }

    // redo undo
    public redo(): void {
        this.pageModel.operationCoordinator.redo()
    }
    public undo(): void {
        this.pageModel.operationCoordinator.undo()
    }
    public get hasRedoFunction(): boolean {
        return this.pageModel.operationCoordinator.hasRedoFunction
    }
    public get hasUndoFunction(): boolean {
        return this.pageModel.operationCoordinator.hasUndoFunction
    }

    public setJa(): void {
        this.pageModel.setLang(LangCode.default)
    }
    public setEn(): void {
        this.pageModel.setLang(LangCode.en)
    }
    public get showMultiLang(): boolean {
        return this.pageModel.isShowMultiLang
    }
    public get lang(): LangCode {
        return this.pageModel.languageCoordinator.lang
    }
}
