import { render, staticRenderFns } from "./questionTemplate.vue?vue&type=template&id=daa557c8&scoped=true"
import script from "./questionTemplate.vue?vue&type=script&lang=ts"
export * from "./questionTemplate.vue?vue&type=script&lang=ts"
import style0 from "./questionTemplate.vue?vue&type=style&index=0&id=daa557c8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daa557c8",
  null
  
)

export default component.exports