
import type { OtherResource } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import CardItem from './items.vue'

@Component({
    components: {
        CardItem,
    },
})
export default class ResoucePicker extends Vue {
    @Prop({ required: true }) resources!: OtherResource[]
    @Prop({ required: true }) value!: string
    public emit(input: string): void {
        this.$emit('input', input)
        this.isShow = false
    }
    public get selectedResource(): OtherResource | null {
        return this.resources.find((item) => item.uuid === this.value) || null
    }
    public isShow = false
    public toggle(): void {
        this.isShow = !this.isShow
    }
}
