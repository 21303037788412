
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionSubtitle from './questionSubtitle.vue'
@Component({
    components: {
        QuestionSubtitle,
    },
})
export default class QuestionTemplate extends Vue {
    @Prop({ required: true }) title!: string
    @Prop({ default: '' }) subTitle!: string
}
