
import { ChillnnAtomModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import TextInput from '../../Atom/textInput.vue'

@Component({
    components: {
        QuestionTemplate,
        TextInput,
    },
})
export default class QuestionID extends Vue {
    @Prop({ required: true }) public atomModel!: ChillnnAtomModel

    public get id(): string | null {
        return this.atomModel.id
    }
    public set id(id: string | null) {
        this.atomModel.id = id
    }
}
