
import type { GeoLocation, AtomMapModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { MapType } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
import SelectBox, { SelectItem } from '../../Molecule/SelectBox/index.vue'
import AppInput from '../../Atom/textInput.vue'
import RangePicker from '../../Atom/rangePicker.vue'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
        SelectBox,
        AppInput,
        RangePicker,
    },
})
export default class QuestionMap extends Vue {
    @Prop({ required: true }) atomModelMap!: AtomMapModel
    public readonly kv: SelectItem[] = [
        {
            key: '住所で指定する',
            value: MapType.ADDRESS,
        },
        {
            key: '緯度・経度を指定する',
            value: MapType.GEOLOCATION,
        },
    ]

    // geoLocation
    @Prop({ required: true }) geoLocationSetter!: (geoLocation: GeoLocation) => void
    public set localGeoLocation(localGeoLocation: GeoLocation) {
        this.geoLocationSetter(localGeoLocation)
    }
    public get localGeoLocation(): GeoLocation {
        return this.atomModelMap.geoLocation
    }
}
