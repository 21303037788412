
import { Component } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class CopyButton extends Vue {
    public copy(): void {
        this.$emit('copy')
    }
}
