
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import SelectItemButton from './selectItem.vue'
import vClickOutside from 'v-click-outside'
export type SelectItem = {
    value: string | number | null
    key: string
    style?: any
}
@Component({
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        SelectItemButton,
    },
})
export default class SelectBox extends Vue {
    @Prop({ required: true }) public data!: SelectItem[]
    public isShow = false
    // v-model
    @Prop({ required: true }) value!: string
    @Prop({ default: false }) closeAfterSelect!: boolean
    public set localValue(input: string) {
        this.$emit('input', input)
        if (this.closeAfterSelect) {
            this.isShow = false
        }
    }
    public get localValue(): string {
        return this.value
    }

    public get selectedValue(): SelectItem {
        const selected = this.data.find((item) => item.value === this.value)
        if (selected) {
            return selected
        } else {
            return {
                value: this.value,
                key: '選択する',
            }
        }
    }

    public selected(input: string): void {
        this.localValue = input
    }

    public toggle(): void {
        this.isShow = !this.isShow
    }
}
