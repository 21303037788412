
import type { ChillnnContentsPage, StudioClickEventHandler } from '@CHILLNN-Inc/chillnn-abr-studio'
import { LangCode } from '@CHILLNN-Inc/chillnn-abr-studio/dist/entity/type'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { StyledDiv } from './common/StyledComponentFactory'
// component
import ChillnnPageRenderer from './components/ChillnnPageRenderer.vue'
// animation
import AOS from 'aos'
import 'aos/dist/aos.css'

@Component({
    components: {
        StyledDiv,
        ChillnnPageRenderer,
    },
})
export default class ChillnnRenderer extends Vue {
    @Prop({ required: true }) page!: ChillnnContentsPage // ページのJSON
    @Prop({ required: true }) clickEventHandler!: StudioClickEventHandler // イベントハンドラー
    @Prop({ default: 1440 }) width!: number // ページの横幅
    @Prop({ default: LangCode.default }) lang!: LangCode // 言語設定

    // アニメーションの初期化
    public created(): void {
        AOS.init({
            offset: 0,
        })
    }
}
