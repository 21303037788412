
import type { OtherResource } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class ResourceItem extends Vue {
    @Prop({ required: true }) resource!: OtherResource
    @Prop({ required: true }) uuid!: string

    public click(): void {
        if (!this.isSelect) {
            this.$emit('click', this.resource.uuid)
        } else {
            this.$emit('click', null)
        }
    }

    public get style(): ICSS {
        return {
            backgroundImage: `url(${this.resource.thumbnail})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }
    public get isSelect(): boolean {
        return this.uuid === this.resource.uuid
    }
}
