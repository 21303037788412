import { ChillnnContentsPageVueModel, position } from '@/lib-components/editor/models/pageModel'
import { ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'

type RulerPosition = {
    top: number
    left: number
}
export class RulerModel {
    private position: RulerPosition
    constructor(
        private readonly mode: 'Vertical' | 'Horizontal',
        private pageModel: ChillnnContentsPageVueModel,
        private addSelf: (self: RulerModel) => void,
        private remove: (self: RulerModel) => void, //
        init?: RulerPosition
    ) {
        this.position = init || { top: 0, left: 0 }
    }
    /*********************************
     * ルーラーの削除を行う
     *********************************/
    public removeSelf(): void {
        // history用のやつ
        this.pageModel.operationCoordinator.addOperation({
            operation: () => this.remove(this),
            inverseOperation: () => this.addSelf(this),
        })
        this.remove(this)
    }
    /*********************************
     * ルーラーの位置を変更する関数を設定する
     *********************************/
    public set(): void {
        let func: (diff: position) => void
        switch (this.mode) {
            case 'Vertical':
                func = (diff: position) => {
                    this.position.top = Math.max(0, this.position.top + diff.y)
                }
                break
            case 'Horizontal':
                func = (diff: position) => {
                    this.position.left = Math.max(0, this.position.left + diff.x)
                }
                break
        }
        this.pageModel.setSetPosition(func)
    }
    /*********************************
     * スタイル
     *********************************/
    public get style(): ICSS {
        if (this.mode === 'Vertical') {
            return {
                top: `${this.position.top}px`,
                left: `0px`,
            }
        } else if (this.mode === 'Horizontal') {
            return {
                bottom: `0px`,
                left: `${this.position.left}px`,
            }
        }
        return {}
    }
    public get top(): number {
        return this.position.top
    }
    public get rulerMode(): 'Vertical' | 'Horizontal' {
        return this.mode
    }
}
