
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { BubbleMenu, Editor } from '@tiptap/vue-2'

@Component({
    components: {
        BubbleMenu,
    },
})
export default class BubbleMenuChillnn extends Vue {
    @Prop({ required: true }) editor!: Editor
}
