var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.removeEditor),expression:"removeEditor"}],staticClass:"html",class:{
        move: !_vm.showEditor,
        isLink: _vm.isLink,
        multipleSelected: _vm.multipleSelected,
        isSimilarWidth: _vm.isSimilarWidth,
        isSimilarHeight: _vm.isSimilarHeight,
        isSimilarTop: _vm.isSimilarTop,
        isSimilarLeft: _vm.isSimilarLeft,
        isSimilarRight: _vm.isSimilarRight,
    },style:(_vm.wrapperStyle),on:{"click":[function($event){$event.stopPropagation();if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.select.apply(null, arguments)},function($event){if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.shiftSelect.apply(null, arguments)}],"mousedown":_vm.dragAndMarginHTML,"mouseup":_vm.removeDragHandler,"mouseenter":_vm.shiftSelectMouseEnter}},[_c('padding-handler',{attrs:{"showHandler":!_vm.showEditor,"isSelected":_vm.isSelected,"sizeModel":_vm.responsiveModel.size,"spaceModel":_vm.responsiveModel.space,"radiusModel":_vm.responsiveModel.border,"pageModel":_vm.pageModel,"htmlModel":_vm.atomModel,"positionHandler":_vm.dragAndMargin}},[(_vm.isSelected && _vm.showEditor)?_c('editor',{model:{value:(_vm.atomModel.src),callback:function ($$v) {_vm.$set(_vm.atomModel, "src", $$v)},expression:"atomModel.src"}}):_c('div',{staticClass:"render_html",domProps:{"innerHTML":_vm._s(_vm.srcRaw)},on:{"dblclick":_vm.setEditor}})],1),(_vm.isSelected && !_vm.showEditor)?_c('div',{staticClass:"modal_container right",style:({ ..._vm.buttonRotate })},[_c('link-button',{attrs:{"pageModel":_vm.pageModel,"linkModel":_vm.linkModel}}),_c('copy-button',{on:{"copy":_vm.copy}}),_c('delete-button',{on:{"click":_vm.deleteSelf}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }