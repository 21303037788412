
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { SelectItem } from './index.vue'

@Component({})
export default class SelectItemButton extends Vue {
    @Prop({ required: true }) public selectedValue!: string
    @Prop({ required: true }) public selectItem!: SelectItem
    public select(): void {
        this.$emit('select', this.selectItem.value)
    }
    public get isSelect(): boolean {
        return this.selectedValue === this.selectItem.value
    }
}
