
import { ICSS, StudioImageMast } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class ImageListItem extends Vue {
    @Prop({ required: true }) studioImageMast!: StudioImageMast
    public select(): void {
        this.$emit('select', this.studioImageMast.src)
    }
    public get style(): ICSS {
        return {
            backgroundImage: `url(${this.studioImageMast.src})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }
    public deleteImage(): void {
        this.$emit('delete', this.studioImageMast.uuid)
    }
}
