import { AtomHtmlVueModel } from '../models/atoms/htmlModel';
import { ChillnnContentsPageVueModel } from '../models/pageModel';

export class EventListenerHandler {
    constructor(
        private pageModel: ChillnnContentsPageVueModel, //
    ) {}

    public deleteHandler(): void {
        // objectの削除
        window.addEventListener('keydown', (e: KeyboardEvent) => {
            const target = e.target as Element;
            if (e.key === 'Backspace' && target.nodeName !== 'INPUT') {
                if (this.pageModel.atomModel && this.pageModel.atomModel.atomType === 'Html') {
                    const htmlModel = this.pageModel.atomModel as AtomHtmlVueModel;
                    if (!htmlModel.isShowEditor) {
                        this.pageModel.atomModel.deleteSelf();
                    }
                } else if (this.pageModel.atomModel) {
                    this.pageModel.atomModel.deleteSelf();
                } else if (this.pageModel.boxModel) {
                    this.pageModel.boxModel.deleteSelf();
                }
            }
        });
    }

    public backHandler(): void {
        // cmd + z で戻る
        window.addEventListener('keydown', (e: KeyboardEvent) => {
            if (this.pageModel.atomModel && this.pageModel.atomModel.atomType === 'Html') {
                const htmlModel = this.pageModel.atomModel as AtomHtmlVueModel;
                if (htmlModel.isShowEditor) {
                    return;
                }
            }
            if ((e.metaKey || e.ctrlKey) && e.key === 'z' && e.shiftKey) {
                // 進む
                this.pageModel.operationCoordinator.redo();
            } else if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
                // 戻る
                this.pageModel.operationCoordinator.undo();
            }
        });
    }

    public navigationGuard(): void {
        //
        window.onbeforeunload = this.beforeUnloadFunc;
    }

    private beforeUnloadFunc(e: BeforeUnloadEvent) {
        e.returnValue = 'このページを離れますか？';
        return 'このページを離れますか？';
    }
}
