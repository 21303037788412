
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { RulerModel } from '../../models/RulerModel'

@Component({})
export default class Ruler extends Vue {
    @Prop({ required: true }) public ruler!: RulerModel

    public set(): void {
        this.ruler.set()
    }
}
