import { ChillnnContentsPageVueModel, position } from '../models/pageModel';

export class CrossKeyHandler {
    public getDiff(event: KeyboardEvent): position {
        if (event.shiftKey) {
            switch (event.key) {
                case 'ArrowUp':
                case 'Up':
                    return { x: 0, y: -10 };
                case 'ArrowRight':
                case 'Right':
                    return { x: 10, y: 0 };
                case 'ArrowDown':
                case 'Down':
                    return { x: 0, y: 10 };
                case 'ArrowLeft':
                case 'Left':
                    return { x: -10, y: 0 };
                default:
                    return { x: 0, y: 0 };
            }
        } else {
            switch (event.key) {
                case 'ArrowUp':
                case 'Up':
                    return { x: 0, y: -1 };
                case 'ArrowRight':
                case 'Right':
                    return { x: 1, y: 0 };
                case 'ArrowDown':
                case 'Down':
                    return { x: 0, y: 1 };
                case 'ArrowLeft':
                case 'Left':
                    return { x: -1, y: 0 };
                default:
                    return { x: 0, y: 0 };
            }
        }
    }

    private diffSum: position = { x: 0, y: 0 };

    public init(
        pageModel: ChillnnContentsPageVueModel,
        diffHandler: (diff: position) => void, //
        endHandler: (diffSum: position) => void,
    ): void {
        addEventListener('keydown', (event) => {
            // 差分処理を実行
            const diff = this.getDiff(event);
            diffHandler(diff);
            // 合計の差分を算出する
            this.diffSum.x += diff.x;
            this.diffSum.y += diff.y;
        });
        addEventListener('keyup', () => {
            // 差分合計の処理をする
            endHandler(this.diffSum);
            // 初期値のリセットを行う
            this.diffSum = { x: 0, y: 0 };
        });
    }
}

export const crossKeyHandler = new CrossKeyHandler();
