
import { ChillnnContentsBoxModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
// component
import QuestionTemplate from '../../Templates/questionTemplate.vue'
import QuestionSubtitle from '../../Templates/questionSubtitle.vue'
@Component({
    components: {
        QuestionTemplate,
        QuestionSubtitle,
    },
})
export default class ContentsBox extends Vue {
    @Prop({ required: true }) public boxModel!: ChillnnContentsBoxModel

    public createText(): void {
        this.boxModel.contentsRepository.addHTML()
    }

    public createImage(): void {
        this.boxModel.contentsRepository.addBoxImage('https://prd-assets.chillnn.com/public/assets/CHILLNN_LOADING.jpg')
    }

    public createMap(): void {
        this.boxModel.contentsRepository.addMap()
    }

    public createYoutube(): void {
        this.boxModel.contentsRepository.addYoutube()
    }

    public createLine(): void {
        this.boxModel.contentsRepository.addLine()
    }
}
