
import type { ChillnnContentsBox, ChillnnContentsBoxModel, ChillnnContentsPageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { ContentsDuplicate } from '@CHILLNN-Inc/chillnn-abr-studio/dist/service/duplicate'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class CopyButton extends Vue {
    @Prop({ required: true }) public pageModel!: ChillnnContentsPageModel
    @Prop({ required: true }) public box!: ChillnnContentsBoxModel

    public copy(): void {
        const originalBox = JSON.parse(JSON.stringify(this.box.rawData))
        const newBox = JSON.parse(JSON.stringify(ContentsDuplicate.duplicateBox(originalBox))) as ChillnnContentsBox
        this.pageModel.insertBox(newBox, this.box.uuid)
    }
}
