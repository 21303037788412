
import { FontTextAlign } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class TextAlign extends Vue {
    @Prop() value!: FontTextAlign
    public setValue(input: FontTextAlign): void {
        this.$emit('input', input)
    }
    public get getValue(): FontTextAlign {
        return this.value
    }
}
