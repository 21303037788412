
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class Modal extends Vue {
    @Prop({ required: true }) public isShowModal!: boolean
    @Prop({ required: true }) public close!: () => void
    @Prop({ required: true }) title!: string
}
