
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({})
export default class AppRadioButtonListItem extends Vue {
    @Prop() public value!: string
    @Prop() public text!: string
    @Prop() public target!: string
    @Prop() public name!: string
    //   @Prop() public description: string | undefined;

    public get localValue(): string {
        return this.value
    }

    public set localValue(value: string) {
        this.$emit('input', value)
    }
}
