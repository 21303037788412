
import { Component } from 'vue-property-decorator'
import { AtomRendererBase } from './base'
//
import { StyledDiv } from '../../common/StyledComponentFactory'
import { AtomLineModel, ICSS } from '@CHILLNN-Inc/chillnn-abr-studio'

@Component({
    components: {
        StyledDiv,
    },
})
export default class LineRenderer extends AtomRendererBase {
    public get customPC(): ICSS {
        return {
            ...this.pcStyle,
            transformOrigin: '0 0',
            padding: undefined,
        }
    }
    public get customMobile(): ICSS {
        return {
            ...this.mobileStyle,
            transformOrigin: '0 0',
            padding: undefined,
        }
    }
    public get paddingPC(): ICSS {
        return {
            padding: this.pcStyle.padding,
        }
    }
    public get paddingMobile(): ICSS {
        return {
            padding: this.mobileStyle.padding,
        }
    }
    public get bgPc(): ICSS {
        return (this.atomModel as AtomLineModel).backgroundCssPc
    }
    public get bgMobile(): ICSS {
        return (this.atomModel as AtomLineModel).backgroundCssMobile
    }
}
