export const fontRepository: () => { key: string; value: string; style?: any }[] = () => {
    return [
        {
            key: '======= 日本語 =======',
            value: `ja`,
            style: {
                fontFamily: `YuGothic,'Yu Gothic',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            },
        },
        {
            key: '游ゴシック',
            value: `YuGothic,'Yu Gothic',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            style: {
                fontFamily: `YuGothic,'Yu Gothic',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            },
        },
        {
            key: 'Noto Sans JP',
            value: `'Noto Sans JP', sans-serif`,
            style: {
                fontFamily: `'Noto Sans JP', sans-serif`,
            },
        },
        {
            key: 'Noto Serif JP',
            value: `'Noto Serif JP', serif`,
            style: {
                fontFamily: `'Noto Serif JP', serif`,
            },
        },
        {
            key: 'メイリオ',
            value: `'メイリオ', Meiryo,'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            style: {
                fontFamily: `'メイリオ', Meiryo,'Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            },
        },
        {
            key: 'klee',
            value: `'Klee One','ヒラギノ明朝 ProN','Hiragino Mincho ProN','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体', sans-serif`,
            style: {
                fontFamily: `'Klee One','ヒラギノ明朝 ProN','Hiragino Mincho ProN','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体', sans-serif`,
            },
        },
        {
            key: 'Dela Gothic One',
            value: `'Dela Gothic One', cursive`,
            style: {
                fontFamily: `'Dela Gothic One', cursive`,
            },
        },
        {
            key: 'New Tegomin',
            value: `'New Tegomin', serif`,
            style: {
                fontFamily: `'New Tegomin', serif`,
            },
        },
        {
            key: 'Kiwi Maru',
            value: `'Kiwi Maru', serif`,
            style: {
                fontFamily: `'Kiwi Maru', serif`,
            },
        },
        {
            key: 'RocknRoll One',
            value: `'RocknRoll One', sans-serif`,
            style: {
                fontFamily: `'RocknRoll One', sans-serif`,
            },
        },
        {
            key: 'Train One',
            value: `'Train One', cursive`,
            style: {
                fontFamily: `'Train One', cursive`,
            },
        },
        {
            key: 'M PLUS Rounded 1c',
            value: `'M PLUS Rounded 1c', sans-serif`,
            style: {
                fontFamily: `'M PLUS Rounded 1c', sans-serif`,
            },
        },
        {
            key: 'M PLUS 1p',
            value: `'M PLUS 1p', sans-serif`,
            style: {
                fontFamily: `'M PLUS 1p', sans-serif`,
            },
        },
        {
            key: 'Sawarabi Mincho',
            value: `'Sawarabi Mincho', sans-serif`,
            style: {
                fontFamily: `'Sawarabi Mincho', sans-serif`,
            },
        },
        {
            key: 'Shippori Mincho',
            value: `'Shippori Mincho', serif`,
            style: {
                fontFamily: `'Shippori Mincho', serif`,
            },
        },
        {
            key: 'Shippori Mincho B1',
            value: `'Shippori Mincho B1', serif`,
            style: {
                fontFamily: `'Shippori Mincho B1', serif`,
            },
        },
        {
            key: 'Kosugi Maru',
            value: `'Kosugi Maru', sans-serif`,
            style: {
                fontFamily: `'Kosugi Maru', sans-serif`,
            },
        },
        {
            key: 'DotGothic16',
            value: `'DotGothic16', sans-serif`,
            style: {
                fontFamily: `'DotGothic16', sans-serif`,
            },
        },
        // ======================= 英字フォント =======================
        {
            key: '======= 英語 =======',
            value: `en`,
            style: {
                fontFamily: `YuGothic,'Yu Gothic',YuGothic,'Yu Gothic','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3',sans-serif`,
            },
        },
        {
            key: 'Josefin Sans',
            value: `'Josefin Sans', sans-serif`,
            style: {
                fontFamily: `'Josefin Sans', sans-serif`,
            },
        },
        {
            key: 'Didact Gothic',
            value: `'Didact Gothic', sans-serif`,
            style: {
                fontFamily: `'Didact Gothic', sans-serif`,
            },
        },
        {
            key: 'Source Sans Pro',
            value: `'Source Sans Pro', sans-serif`,
            style: {
                fontFamily: `'Source Sans Pro', sans-serif`,
            },
        },
        {
            key: 'Times New Roman',
            value: `'Times New Roman',sans-serif`,
            style: {
                fontFamily: `'Times New Roman',sans-serif`,
            },
        },
        {
            key: 'Yanone Kaffeesatz',
            value: `'Yanone Kaffeesatz', sans-serif`,
            style: {
                fontFamily: `'Yanone Kaffeesatz', sans-serif`,
            },
        },
        {
            key: 'Raleway',
            value: `'Raleway', sans-serif`,
            style: {
                fontFamily: `'Raleway', sans-serif`,
            },
        },
        {
            key: 'Playfair Display',
            value: `'Playfair Display', serif`,
            style: {
                fontFamily: `'Playfair Display', serif`,
            },
        },
        {
            key: 'Cinzel',
            value: `'Cinzel', serif`,
            style: {
                fontFamily: `'Cinzel', serif`,
            },
        },
        {
            key: 'Nixie One',
            value: `'Nixie One', cursive`,
            style: {
                fontFamily: `'Nixie One', cursive`,
            },
        },
        {
            key: 'Amatic SC',
            value: `'Amatic SC', cursive`,
            style: {
                fontFamily: `'Amatic SC', cursive`,
            },
        },
        {
            key: 'Kranky',
            value: `'Kranky', cursive`,
            style: {
                fontFamily: `'Kranky', cursive`,
            },
        },
        {
            key: 'Impact',
            value: `'Impact','DIN Condensed',sans-serif`,
            style: {
                fontFamily: `'Impact','DIN Condensed',sans-serif`,
            },
        },
        {
            key: 'Courier',
            value: `'Courier',sans-serif`,
            style: {
                fontFamily: `'Courier',sans-serif`,
            },
        },
    ]
}
