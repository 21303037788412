
import type { ChillnnContentsBox, ChillnnContentsPageModel, ChillnnImage, IChillnnTemplatePageModel } from '@CHILLNN-Inc/chillnn-abr-studio'
import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import ChillnnBoxRenderer from '@/lib-components/renderer/components/ChillnnBoxRenderer.vue'
import SnackTemplateContents from './snackTemplateContents.vue'
export type Category = {
    key: string
    value: string
}
@Component({
    components: {
        ChillnnBoxRenderer,
        SnackTemplateContents,
    },
})
export default class SnackTemplateCard extends Vue {
    @Prop({ required: true }) templatePageModel!: IChillnnTemplatePageModel | null
    @Prop({ required: true }) pageModel!: ChillnnContentsPageModel | null
    @Prop({ required: true }) closeModal!: () => void
    @Prop({ required: true }) public selectedSnackCategory!: Category
    public box: ChillnnContentsBox | null = null

    public get isShowDetail(): boolean {
        return !!this.templatePageModel
    }

    public get thumbnail(): ChillnnImage {
        if (!this.templatePageModel) return ''
        return this.templatePageModel.thumbnail
    }
    public get title(): string {
        if (!this.templatePageModel) return ''
        return this.templatePageModel.title
    }
    public get uuid(): string {
        if (!this.templatePageModel) return ''
        return this.templatePageModel.uuid
    }
    public changeDetail(): void {
        this.$emit('select', null)
    }
}
