import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { crossKeyHandler } from '../../../eventListeners/crossKeyHandler'
import { ChillnnContentsPageVueModel, position } from '../../../models/pageModel'

@Component
export class CrossKeyEventMixin extends Vue {
    @Prop({ required: true }) pageModel!: ChillnnContentsPageVueModel
    // ============== cross move ==============
    public crossKeyMoveHandler(diff: position): void {
        if (this.pageModel.setPosition) {
            this.setInitPositionForCrossKey({ x: 0, y: 0 })
            this.pageModel.setPosition(diff)
        }
    }
    public crossKeyMoveHandlerEnd(diffSum: position): void {
        this.endPositionForCrossKey = { ...diffSum }
        this.addHistoryForCrossKey()
    }
    // ============== private ==============
    /** init position */
    private initPositionForCrossKey: position | null = null
    private endPositionForCrossKey: position | null = null
    private setInitPositionForCrossKey(start: position): void {
        this.initPositionForCrossKey = { ...start }
        this.endPositionForCrossKey = { ...start }
    }
    private addHistoryForCrossKey(): void {
        if (this.initPositionForCrossKey && this.endPositionForCrossKey) {
            const diff = {
                x: this.endPositionForCrossKey.x - this.initPositionForCrossKey.x,
                y: this.endPositionForCrossKey.y - this.initPositionForCrossKey.y,
            }
            if (!diff.x && !diff.y) {
                // 変化がない場合
                return
            }
            const setPosition = this.pageModel.setPosition
            if (setPosition) {
                this.pageModel.operationCoordinator.addOperation({
                    operation: () => setPosition(diff),
                    inverseOperation: () => setPosition({ x: -diff.x, y: -diff.y }),
                })
            }
        }
        this.endPositionForCrossKey = null
        this.initPositionForCrossKey = null
    }

    public created(): void {
        crossKeyHandler.init(
            this.pageModel, //
            this.crossKeyMoveHandler,
            this.crossKeyMoveHandlerEnd
        )
    }
}
