import { StudioImageMast } from '@CHILLNN-Inc/chillnn-abr-studio'

export const imageRepository: () => StudioImageMast[] = () =>
    [
        'https://images.unsplash.com/photo-1566027325887-5370603d57bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1051&q=80',
        'https://images.unsplash.com/photo-1576771561255-dafc6c8789aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=619&q=80',
        'https://images.unsplash.com/photo-1480796927426-f609979314bd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
        'https://images.unsplash.com/photo-1614640522775-71bebddd21f9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
        'https://images.unsplash.com/photo-1509281373149-e957c6296406?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cmV0cm98ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1603993097397-89c963e325c7?ixid=MXwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80', //
        'https://images.unsplash.com/photo-1613367046430-fa9eab3014f1?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1613807871118-9e983601b759?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=675&q=80',
        'https://images.unsplash.com/photo-1613749030206-9f59d27a28b8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1613761039741-89d469dbe16a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1613676169586-0135c5fa3b28?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1613776532187-a406e53d99a5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
        'https://images.unsplash.com/photo-1613724131628-a20a5b0a4b91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1225&q=80',
        'https://images.unsplash.com/photo-1613761923170-6af29db82280?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80',
        'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
        'https://images.unsplash.com/photo-1616705900481-87d8044f1d3b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1268&q=80',
        'https://images.unsplash.com/photo-1616709620730-0058222c8389?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80',
        'https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=726&q=80',
        'https://images.unsplash.com/photo-1612902376658-d4d46558ee04?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1561453640-e2b9330b4a41?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1490481651871-ab68de25d43d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
        'https://images.unsplash.com/photo-1517213830215-ad88cda1f131?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1068&q=80',
        'https://images.unsplash.com/photo-1533759558691-15b2e8db6ddc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3310&q=80',
        'https://images.unsplash.com/photo-1600609577766-8a2b46aaafc3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1534&q=80',
        'https://images.unsplash.com/photo-1559733883-9e079247b671?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3300&q=80',
        'https://images.unsplash.com/photo-1595664248149-94356b1ae05c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2200&q=80',
        'https://images.unsplash.com/photo-1454789548928-9efd52dc4031?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
        'https://images.unsplash.com/photo-1612487439056-f2891265d047?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=5100&q=80',
        'https://images.unsplash.com/photo-1506344630830-d393262e723c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8bW9ub2Nocm9tZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1505496704829-37e28089504e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fG1vbm9jaHJvbWV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1605596888915-c823446b43fa?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjF8fG1vbm9jaHJvbWV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1617721926586-4eecce739745?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',

        'https://images.unsplash.com/photo-1619123609591-f4c69f96f979?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1591700151209-540626cf808c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTV8fGZvb3R8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1611653842967-39eb011b2ca3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8N3x8ZWFycmluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1580569448936-ba724baadbd4?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTY4fHxyb3NlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1494438639946-1ebd1d20bf85?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1047&q=80',
        'https://images.unsplash.com/photo-1519225924453-b28226426689?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YmxhY2slMjBiYWNrZ3JvdW5kfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1602295456965-49e52ca85874?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80',
        'https://images.unsplash.com/photo-1530339807835-4512faf804c1?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80',
        'https://images.unsplash.com/photo-1494949649109-ecfc3b8c35df?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80',

        // pop
        'https://images.unsplash.com/photo-1543604502-9baac28067ad?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fHNhbmR8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1531558506007-fe311a2f4729?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2t5c2NyYXBlcnxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1499793983690-e29da59ef1c2?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmVhY2glMjBob3VzZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1416138645715-930585fe4ce2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
        'https://images.unsplash.com/photo-1602595688238-9fffe12d5af3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8cm9vbXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1598112974331-d999b0b891cc?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODZ8fGZhcm0lMjBmcnVpdHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fG5vdGV8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1488707872600-5507977fe355?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fGhvdXNlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/9/fields.jpg?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8ZmFybXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',

        // main
        'https://images.unsplash.com/photo-1569172122301-bc5008bc09c5?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGFydHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1471899236350-e3016bf1e69e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80',

        'https://editor-assets.chillnn.com/img/1-1.jpeg',
        'https://editor-assets.chillnn.com/img/2-1.jpeg',
        'https://editor-assets.chillnn.com/img/3-1.jpeg',
        'https://editor-assets.chillnn.com/img/3-2.jpeg',
        'https://editor-assets.chillnn.com/img/3-3.jpeg',
        'https://editor-assets.chillnn.com/img/3-4.jpeg',
        'https://editor-assets.chillnn.com/img/4-0.jpeg',
        'https://editor-assets.chillnn.com/img/4-2.jpeg',
        'https://editor-assets.chillnn.com/img/4-3.jpeg',
        'https://editor-assets.chillnn.com/img/4-4.jpeg',
        //
        'https://editor-assets.chillnn.com/img/food1.png',
        'https://editor-assets.chillnn.com/img/food2.png',
        'https://editor-assets.chillnn.com/img/food3.png',
        'https://editor-assets.chillnn.com/img/food4.png',
        'https://editor-assets.chillnn.com/img/food5.png',
        'https://editor-assets.chillnn.com/img/food6.png',
        'https://editor-assets.chillnn.com/img/food7.png',
        //
        //
        'https://editor-assets.chillnn.com/img/fashion01.png',
        'https://editor-assets.chillnn.com/img/fashion02.png',
        'https://editor-assets.chillnn.com/img/fashion03.png',
        'https://editor-assets.chillnn.com/img/fashion04.png',
        'https://editor-assets.chillnn.com/img/fashion05.png',
        'https://editor-assets.chillnn.com/img/fashion06.png',
        'https://editor-assets.chillnn.com/img/fashion07.png',
        'https://editor-assets.chillnn.com/img/fashion08.png',
        //
        'https://editor-assets.chillnn.com/img/toast.png',
        'https://images.unsplash.com/photo-1595950178955-18c1b4723c0e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8bW9ub3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60ss',
        'https://images.unsplash.com/photo-1599070782121-5b0c8d831d15?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YmVpZ2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1580744789283-e7d146bae255?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDJ8fGdyYXl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1521402966881-852a3b2551fc?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTB8fGJlaWdlfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1539929271489-632101aa77cf?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzh8fG1vbm98ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1611394914106-87bd2b422c35?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDR8fG1vbm98ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1564309886762-2b56e288bdb8?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTQ4fHxtb25vfGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://images.unsplash.com/photo-1580360119968-c17a2f251c77?ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODB8fG1vbm98ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
        'https://www.chillnn.com/_nuxt/img/CHILLNN_logo_bk.b5aa3b3.png',
        'https://www.chillnn.com/_nuxt/img/CHILLNN_logo_wh.5d16ba1.png',
    ].map((item) => {
        return {
            ownerID: '',
            uuid: '',
            image: {
                bucket: '',
                key: '',
                region: '',
                mimeType: '',
                fileName: '',
            },
            src: item,
            createdAt: 0,
            deletedAt: 0,
        }
    })
